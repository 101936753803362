<script>

    // @ts-nocheck

    import { onMount, onDestroy } from 'svelte';  
    import { fade, fly } from 'svelte/transition';
    import { fields } from '../../stores/form.js';
    import { user } from '../../stores/user.js';
    import { userObj } from '../../stores/userObj.js';
    import { settings } from '../../stores/settings.js';
    import { validateChars } from '../helper/validate.js';
    import HelpButton from '../helpTextBox/HelpButton.svelte';
    import InputText from '../inputText/InputText.svelte';
    import Explanation from '../text/Explanation.svelte';
    import InputTextUnknown from '../inputText/InputTextUnknown.svelte';
    import InputSelect from '../inputSelect/InputSelect.svelte';
    import InputNumber from '../inputText/InputNumber.svelte';
    import RadioButtonGroup from '../radio/RadioButtonGroup.svelte';
    import InputSelectCountries from '../inputSelect/InputSelectCountries.svelte';
    import ExplanationDocument from '../text/ExplanationDocument.svelte';
   

    // export let fieldName = "none";
    // export let value = "";
    export let store;
    export let labelClass = "daa-label-w400";

    let components = {};
    let inputChildrenYesNo;
    let inputChildrenUnder25;

    $:showWarning = false;
    $:checkSilently = false;
    $:showMoreOptions = false;

    // const numChildrenToRadioValue = () => {
    //     if (userObj["childNumber"] === "") return "";
    //     if (parseInt(userObj["childNumber"]) <1 ) return "no";
    //     return "yes";
    // }

    $:hasChildren = store.hasChildren;
  
    let radioGroupData = {required:true};

    
    let yesNoOptions = [
        {id:"1", groupName:"CHyesNo", label:"Ja",value:"yes"},
        {id:"0", groupName:"CHNyesNo", label:"Nein",value:"no"},
    ];

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const handleChildYesNo = (val) => {
        
        userObj.hasChildren = val;
        if (inputChildrenUnder25) {
            userObj.childNumberUnder25 = inputChildrenUnder25.getValue();
        }
        hasChildren = val;
        showWarning = false;
        $settings.showWarningInFooter = false;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    export function validate (boolSilent) {

        let ch1 = inputChildrenYesNo.validate();
        if ( ch1 === "valid") return "valid";
        return "invalid";
    }

    export const getValue = () => {
    };

    export const update = () => {
        hasChildren = userObj.hasChildren;
        inputChildrenYesNo.setValue(hasChildren);
        if(inputChildrenUnder25) inputChildrenUnder25.setValue(userObj.childNumberUnder25);
    };

    const saveToStore = () => {
        // console.log("ChildrenContainer, saveToStore, hasChildren", hasChildren);
        // store.hasChildren = hasChildren;
   
    }
    
    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    onMount(async () => {
        //components["children"].setValue("no");
	});

    onDestroy(() => {
        // saveToStore();
    });

    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    
</script>

<div >

    <Explanation
        fieldName="explainChildren"
        labelClass="daa-input-grid-300">
    </Explanation>

    <RadioButtonGroup bind:this={inputChildrenYesNo}
        data={$fields["children"]}  
        fieldName="children"
        labelClass="daa-input-grid-300"
        store = {store}
        options = {yesNoOptions}
        checked = {hasChildren}
        callback = {handleChildYesNo}>
    </RadioButtonGroup>

    {#if hasChildren === "yes"}

        <div in:fly={{ y: -100, duration: 250 }} out:fade={{ duration: 20 }}>

            <InputNumber bind:this={inputChildrenUnder25}
                fieldName="childNumberUnder25"
                labelClass="daa-input-grid-300"
                store = {store}>
            </InputNumber>

            <ExplanationDocument 
                fieldName="explainSendDocument"
                labelClass="daa-input-grid-300">
            </ExplanationDocument>
        
        </div>

    {/if}
     
</div>

