<script>

    // @ts-nocheck

    import Footer from '../footer/Footer.svelte';


    /////////////////////////////////////////////////////////////////////////////////////////////////////////


</script>


<div class="daa-main-layout">

    <div></div>

    <div class="daa-impressum">

        <h1>Impressum</h1>
        <p>DAA-Stiftung Bildung und Beruf</p>
        <p>Alter Teichweg 19</p>
        <p>22081 Hamburg</p>
        <br>
        <p>Tel.:     +49(0)40-35094-112</p>
        <p>E-Mail: info.stiftung(at)daa-stiftung.de</p>
        <br>
        <p><strong>Vorstand:</strong></p>
        <p>Christoph Meister (Vorsitzender)</p>
        <p>Martina Dahncke (stv. Vorsitzende und Geschäftsführende Vorständin)</p>
        <p>Jürgen Sattari-Nadjafabadi</p>
        <br>

        <p>Die DAA-Stiftung Bildung und Beruf ist eine gemeinnützige Stiftung des Bürgerlichen Rechts mit Sitz in Hamburg.
            Sie wird bei der Freie und Hansestadt Hamburg - Behörde für Justiz und Verbraucherschutz - J41 - Stiftungsangelegenheiten, Drehbahn 36, 20354 Hamburg, als zuständiger Aufsichtsbehörde unter dem Aktenzeichen 922.16-57 (1923) geführt.</p>

        <h1>Datenschutzhinweise für unsere Honorarkräfte</h1>
        <p>(im Schweizer Modell)</p>
        
        </div>

    <div></div>

 </div>

 <div style="height: 120px;"></div>

<Footer positionFix="true"></Footer>
