<script> 

    import { modal } from "../../stores/modal"; 


    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const hideModal = () => {
        $modal.current = "none";
    }

</script>


{#if $modal.current === "disclaimer"}

<div class="daa-backdrop daa-backdrop-dark" on:click={hideModal} role="none">

    <div class="daa-dialog-container daa-dialog-container-impressum">

        <div class="daa-impressum">
            <h1>Datenschutz</h1>
            <div></div>
            <p>Auf dieser Seite finden Sie die Datenschutzerklärung zur Nutzung der Website sowie Datenschutzhinweise für die Erhebung, Verarbeitung und Speicherung von personenbezogenen, abrechnungsrelevanten Daten im Schweizer Modell nach Art. 13 DSGVO.</p>
            <div></div>
            <p><strong>Datenschutzerklärung zur Nutzung der Website</strong></p>
            <div></div>
            <p>Wir freuen uns über Ihren Besuch auf unserer Webseite. Nachfolgend möchten wir Sie über den Umgang mit Ihren Daten gem. Art. 13 Datenschutz-Grundverordnung (DSGVO) informieren.</p>
            <div></div>
            <p><strong>Verantwortlicher</strong></p>
            <div></div>
            <p>Verantwortlich für die nachfolgend dargestellten Datenverarbeitungen ist die im Impressum genannte Stelle.</p>
            <div></div>
            <p><strong>Nutzungsdaten</strong></p>
            <div></div>
            <p>Wenn Sie unsere Webseiten besuchen, werden auf unserem Webserver temporär sogenannte Nutzungsdaten zu statistischen Zwecken als Protokoll ausgewertet, um die Qualität unserer Webseiten zu verbessern. Dieser Datensatz besteht aus</p>
            <div></div>
            <ul>
                <li>dem Namen und der Adresse der angeforderten Inhalte,</li>
                <li>dem Datum und der Uhrzeit der Abfrage,</li>
                <li>der übertragenen Datenmenge,</li>
                <li>dem Zugriffsstatus (Inhalt übertragen, Inhalt nicht gefunden),</li>
                <li>der Beschreibung des verwendeten Webbrowsers und des Betriebssystems</li>
            </ul>

            <div></div>
            <p>Rechtsgrundlage der Verarbeitung der Nutzungsdaten ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Die Verarbeitung erfolgt in dem berechtigten Interesse, die Inhalte der Webseite bereitzustellen und eine Geräte- sowie Browseroptimierte Darstellung sicherzustellen.</p>
            <div></div>

            <p><strong>Datensicherheit</strong></p>
            <div></div>
            <p>Um Ihre Daten vor unerwünschten Zugriffen möglichst umfassend zu schützen, treffen wir technische und organisatorische Maßnahmen. Wir setzen auf unseren Webseiten ein Verschlüsselungsverfahren ein. Ihre Angaben werden von Ihrem Rechner zu unserem Server und umgekehrt über das Internet mittels einer TLS-Verschlüsselung übertragen. Sie erkennen dies in der Regel daran, dass in der Statusleiste Ihres Browsers das Schloss-Symbol geschlossen ist und die Adresszeile mit https:// beginnt.</p>
            <div></div>

            <p><strong>Weitere Auftragsverarbeiter</strong></p>
            <div></div>
            <p>Wir geben Ihre Daten im Rahmen einer Auftragsverarbeitung gem. Art. 28 DSGVO an Dienstleister weiter, die uns beim Betrieb unserer Webseiten und der damit zusammenhängenden Prozesse unterstützen. Das sind z.B. Hosting-Dienstleister. Unsere Dienstleister sind uns gegenüber streng weisungsgebunden und entsprechend vertraglich verpflichtet.</p>
            <div></div>
            <p>Nachfolgend nennen wir Ihnen die Auftragsverarbeiter, mit denen wir zusammenarbeiten, sofern wir dies im vorstehenden Text der Datenschutzerklärung noch nicht getan haben. Sofern in diesem Rahmen Daten außerhalb der EU bzw. des EWR verarbeitet werden können, informieren wir Sie hierüber in der nachfolgenden Tabelle.</p>
            <div></div>
            <br>
            <table style="width:100%; text-align:left; font-size: 14px; line-height: 1.4;">
                <tr>
                  <th>Auftragsverarbeiter</th>
                  <th>Zweck</th>
                  <th>Angemessenes Datenschutzniveau</th>
                </tr>
                <tr>
                  <td>GOPAS Solutions GmbH <br>
                    Schellerdamm 16 <br>
                    21079 Hamburg (Deutschland)</td>
                  <td>Webhosting</td>
                  <td>Verarbeitung nur innerhalb EU/EWR</td>
                </tr>
              </table>

            <br>

            <p><strong>Ihre Rechte als betroffene Person</strong></p>
            <div></div>
            <p>Bei der Verarbeitung Ihrer personenbezogenen Daten gewährt die DSGVO Ihnen als betroffene Person bestimmte Rechte:</p>
            <div></div>
            <p><strong>Auskunftsrecht (Art. 15 DSGVO)</strong></p>
            <div></div>
            <p>Sie haben das Recht eine Bestätigung darüber zu verlangen, ob Sie betreffende personenbezogene Daten verarbeitet werden; ist dies der Fall, so haben Sie ein Recht auf Auskunft über diese personenbezogenen Daten und auf die in Art. 15 DSGVO im Einzelnen aufgeführten Informationen.</p>
            <p><strong>Recht auf Berichtigung (Art. 16 DSGVO)</strong></p>
            <div></div>
            <p>Sie haben das Recht, unverzüglich die Berichtigung Sie betreffender unrichtiger personenbezogener Daten und ggf. die Vervollständigung unvollständiger Daten zu verlangen.</p>
            <p><strong>Recht auf Löschung (Art. 17 DSGVO)</strong></p>
            <div></div>
            <p>Sie haben das Recht, zu verlangen, dass Sie betreffende personenbezogene Daten unverzüglich gelöscht werden, sofern einer der in Art. 17 DSGVO im Einzelnen aufgeführten Gründe zutrifft.</p>
            <p><strong>Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO)</strong></p>
            <div></div>
            <p>Sie haben das Recht, die Einschränkung der Verarbeitung zu verlangen, wenn eine der in Art. 18 DSGVO aufgeführten Voraussetzungen gegeben ist, z. B. wenn Sie Widerspruch gegen die Verarbeitung eingelegt haben, für die Dauer der Prüfung durch den Verantwortlichen.</p>
            <p><strong>Recht auf Datenübertragbarkeit (Art. 20 DSGVO)</strong></p>
            <div></div>
            <p>In bestimmten Fällen, die in Art. 20 DSGVO im Einzelnen aufgeführt sind, haben Sie das Recht, die Sie betreffenden personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten bzw. die Übermittlung dieser Daten an einen Dritten zu verlangen.</p>
            <p><strong>Widerrufsrecht (Art. 7 DSGVO)</strong></p>
            <div></div>
            <p>Sofern die Verarbeitung von Daten auf Grundlage Ihrer Einwilligung erfolgt, sind Sie nach Art. 7 Abs. 3 DSGVO berechtigt, die Einwilligung in die Verwendung Ihrer personenbezogenen Daten jederzeit zu widerrufen. Bitte beachten Sie, dass der Widerruf erst für die Zukunft wirkt. Verarbeitungen, die vor dem Widerruf erfolgt sind, sind davon nicht betroffen.</p>
            <p><strong>Widerspruchsrecht (Art. 21 DSGVO)</strong></p>
            <div></div>
            <div>Werden Daten auf Grundlage von Art. 6 Abs. 1 S. 1 lit. f DSGVO (Datenverarbeitung zur Wahrung berechtigter Interessen) oder auf Grundlage von Art. 6 Abs. 1 S. 1 lit. e DSGVO (Datenverarbeitung zur Wahrung öffentlichen Interesse oder in Ausübung öffentlicher Gewalt) erhoben, steht Ihnen das Recht zu, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Widerspruch einzulegen. Wir verarbeiten die personenbezogenen Daten dann nicht mehr, es sei denn, es liegen nachweisbar zwingende schutzwürdige Gründe für die Verarbeitung vor, die gegenüber Ihren Interessen, Rechten und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</div>
            <p><strong>Beschwerderecht bei einer Aufsichtsbehörde (Art. 77 DSGVO)</strong></p>
            <div></div>
            <div>Sie haben gem. Art. 77 DSGVO das Recht auf Beschwerde bei einer Aufsichtsbehörde, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden Daten gegen datenschutzrechtliche Bestimmungen verstößt. Das Beschwerderecht kann insbesondere bei einer Aufsichtsbehörde in dem Mitgliedstaat Ihres gewöhnlichen Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes geltend gemacht werden.</div>
            
            <div></div>
            <p><strong>Geltendmachung Ihrer Rechte </strong></p>
            <div></div>
            <div>Sofern vorstehend nichts anderes beschrieben wurde, wenden Sie sich zur Geltendmachung Ihrer Betroffenenrechte bitte an die im Impressum genannte Stelle</div>
            <div></div>

            <p><strong>Kontaktdaten des Datenschutzbeauftragten</strong></p>
            <div></div>
            <div>Unser externer Datenschutzbeauftragter steht Ihnen gerne für Auskünfte zum Thema Datenschutz unter folgenden Kontaktdaten zur Verfügung:</div>
            <br>
            <div>datenschutz nord GmbH</div>
            <div>Konsul-Smidt-Straße 88</div>
            <div>28217 Bremen</div>
            <div>Web: https://www.dsn-group.de/</div>
            <div>office@datenschutz-nord.de</div>
            <br>
            <div>Wenn Sie sich an unseren Datenschutzbeauftragten wenden, geben Sie hierbei bitte auch die verantwortliche Stelle an, die im Impressum genannt wird.</div>

            <div></div>
            <p><strong>Datenschutzhinweise für die Erhebung, Verarbeitung und Speicherung von personenbezogenen, abrechnungsrelevanten Daten im Schweizer Modell</strong></p>
            <div></div>

            <p>Bislang wurden Sie als Honorarkraft im Rahmen Ihrer Tätigkeit für die Unternehmen der DAA-Stiftung Bildung und Beruf abrechnungstechnisch als Selbständige betrachtet. In diesem Zusammenhang war lediglich die Erfassung Ihrer Stammdaten (Vor- und Nachname, Anschrift, Kontaktdaten, Bankverbindung) erforderlich.</p>
            
            <p>Als Honorarkraft im Schweizer Modell gelten Sie sozialversicherungsrechtlich nunmehr als arbeitnehmerähnliche Selbständige in unseren Unternehmen. Damit die Unternehmen der DAA-Stiftung Bildung und Beruf künftig Sozialversicherungsbeiträge für Sie abführen können, ist es erforderlich, die hierfür abrechnungsrelevanten personenbezogenen Daten von Ihnen zu erheben. Diese können Sie über unser [Webportal] angeben und auf sicherem Wege an uns senden. Hiermit erhalten Sie Informationen zur Verarbeitung Ihrer personenbezogenen Daten im Zusammenhang mit Ihrer Tätigkeit als Honorarkraft bzw. arbeitnehmerähnliche Selbständige bei uns:</p>
            <div></div>

            <p><strong>Wer ist für Datenverarbeitung verantwortlich?</strong></p>
            <div></div>
            <p>Grundlage dieser Erklärung ist das jeweilige Vertragsverhältnis zwischen Ihnen und dem jeweiligen Unternehmen der DAA-Stiftungs-Gruppe (bspw. DAA Deutsche Angestellten-Akademie GmbH, DAA-Technikum Gemeinnützige Fernunterrichts-GmbH, HFH Hamburger Fern-Hochschule gemeinnützige GmbH).</p>
            <div></div>
            <p>Verantwortlicher im Sinne des Datenschutzrechts ist das Unternehmen der DAA-Stiftungs-Gruppe mit dem Sie einen Honorarvertrag abgeschossen haben/abschließen werden:</p>
            <div></div>

            <div>DAA Deutsche Angestellten-Akademie GmbH</div>
            <div>Alter Teichweg 19, 22081 Hamburg</div>
            <div>Geschäftsführung: Maria-Carmen Grabenhorst-Hickmann, Dr. Tina Jessica Classen</div>
            <div>Telefax: 040 35094-120</div>
            <div>E-Mail: information@daa.de</div>
            <br>
            <div>DAA-Technikum Gemeinnützige Fernunterrichts-GmbH</div>
            <div>Auf der Union 10, 45141 Essen</div>
            <div>Geschäftsführer: Dr. Martin Bleß</div>
            <div>Telefax: 0201 83 16 311</div>
            <div>E-Mail: info@daa-technikum.de</div>
            <br>
            <div>HFH Hamburger Fern-Hochschule gemeinnützige GmbH</div>
            <div>Alter Teichweg 19, 22081 Hamburg</div>
            <div>Geschäftsführerin: Susanne Möcks-Carone</div>
            <div>Telefax: 040 35094310</div>
            <div>E-Mail: info@hfh-fernstudium.de</div>


            <p>Weitere Informationen und Kontaktdaten finden Sie auch in Ihrem Honorarvertrag.</p>
            <div></div>
            

            <p><strong>Welche Daten von Ihnen werden von uns verarbeitet? Und zu welchen Zwecken?</strong></p>
            <div></div>
            <p>Zunächst verarbeiten wir Ihre Anschrift, um Ihnen einen Usernamen und Initialpasswort zur Anmeldung in unserem [Webportal] zur Verfügung zu stellen. Das Initialpasswort muss bei der erstmaligen Anmeldung durch ein eigenes Passwort ersetzt werden.</p>
            <div></div>
            <p>Wir verarbeiten die von Ihnen im Portal eingegebenen personenbezogenen Daten, die für die Durchführung des Honorarverhältnisses erforderlich sind. Das sind primär Kontaktdaten, Daten zu Ihren Qualifikationen, Einsatzzeiten, aber nunmehr auch Daten zur Sozial- und Rentenversicherung (z.B.  Steuer-ID, Sozialversicherungsnummer, Rentenbescheid, etc.) und alle Informationen, die für die Ermittlung und Abrechnung Ihres Honorars im Schweizer Modell und sonst im Zusammenhang mit gesetzlichen Abgaben und Steuern erforderlich sind.</p>
            <div></div>
            <p>Unabhängig davon kann es immer Konstellationen geben, in denen wir personenbezogenen Daten von Ihnen verarbeiten, die hier nicht bzw. deren Zwecke hier nicht genannt sind. Wir werden in diesen Fällen dann – bezogen auf den jeweiligen Anlass – gesonderte Informationen zum Datenschutz für Sie bereithalten, soweit dies gesetzlich erforderlich ist.</p>
            <div></div>
           
            <p><strong>Auf welcher rechtlichen Grundlage basiert das?</strong></p>
            <div></div>
           
            <p>Rechtsgrundlage für die Verarbeitung Ihrer abrechnungsrelevanten Daten zur Durchführung des Honorarverhältnisses ist zunächst die Datenschutz-Grundverordnung (DSGVO). Nach Art. 88 Abs. 1 DSGVO i.V.m. § 26 Abs. 1 BDSG bzw. Art. 6 Abs. 1 S. 1 lit. b) DSGVO ist die Verarbeitung Ihrer Daten zulässig, wenn dies zur Durchführung oder Beendigung des Beschäftigungsverhältnisses erforderlich ist.</p>
            <div></div>
            <p>Wir können zudem zur Verarbeitung Ihrer Daten gesetzlich verpflichtet sein (bspw. bzgl. Ihrer Daten zur Sozial- und Rentenversicherung) . Rechtsgrundlage der Verarbeitung ist dann Art. 6 Abs. 1 S. 1 lit. c) DSGVO i.V.m. den einschlägigen arbeitsrechtlichen/spezialgesetzlichen Vorschriften.</p>
            <div></div>
            <p>Sofern wir Daten von Ihnen erhalten, die im Portal als freiwillig markiert sind, verarbeiten wir diese  auf Basis Ihrer Einwilligung, die Sie jederzeit mit Wirkung für die Zukunft widerrufen können (siehe - Ihre Rechte als „Betroffene“ – unten).</p>
            <div></div>
            <p>FSchließlich kann eine Datenverarbeitung auch auf Basis einer sog. Interessenabwägung nach Art. 6 Abs. 1 S. 1 lit. f) DSGVO erfolgen. Ein berechtigtes Interesse der DAA besteht z.B. an der Datenverarbeitung zum Zweck der Durchführung von Evaluationen und der Einholung von Feedback, aber auch an der Datenverarbeitung zum Zweck der Abwehr von geltend gemachten Rechtsansprüchen aus dem Honorarverhältnis. In diesen Fällen wird das konkrete Interesse im Zusammenhang mit der jeweiligen Verarbeitung gesondert mitgeteilt.</p>
            <div></div>
            <p>Sofern besondere Arten personenbezogener Daten i.S.d. Art. 9 DSGVO verarbeitet werden (z.B. Gesundheitsdaten) ist die Rechtsgrundlage Art. 88 Abs. 1 DSGVO i.V.m. § 26 Abs. 3, Abs. 8 BDSG bzw. Art. 6 Abs. 1 lit. b) DSGVO i.V.m. Art. 9 Abs. 2 lit. b) DSGVO.</p>
            <div></div>


            <p><strong>Wie lange werden die Daten gespeichert?</strong></p>
            <div></div>

            <p>Zum Zweck der Datenerhebung über unser [Webportal] speichern wir Ihre eingegebenen Daten im Portal selbst nur solange, bis der Prozess zur Stammdatenerfassung für die Honorarkräfte abgeschlossen ist. Sofern Sie zu einem späteren Zeitpunkt die Daten erneut im Portal aufrufen wollen, werden diese lediglich aus der Datenbank gespiegelt, sind aber nicht im Portal direkt gespeichert.</p>
            <div></div>
            <p>Grundsätzlich speichern wir Ihre Daten im Rahmen des Honorarverhältnisses für dessen Dauer, für die Dauer gesetzlicher Aufbewahrungsfristen, für die Dauer geltender Verjährungsfristen für rechtliche Ansprüche aus dem Honorarverhältnis sowie für die Dauer Ihrer geltenden Einwilligung. Wir löschen Ihre Daten, sofern diese für die vorgenannten Punkte nicht mehr erforderlich sind bzw. Sie Ihre Einwilligung widerrufen haben.</p>
            <div></div>
            <p>Wir werden generell zum Ende eines Jahres prüfen, ob und in welchem Umfang abrechnungsrelevante Daten von Honorarkräften wegen eines Wegfalls der Erforderlichkeit gelöscht werden können.</p>
            <div></div>

            <p><strong>Aus welchen Quellen beziehen wir Ihre Daten (Art. 14 DSGVO)?</strong></p>
            <div></div>

            <p>Neben den von Ihnen zur Verfügung gestellten Daten, beziehen wir Daten zu Ihrer Person von folgenden Stellen:</p>
            <div></div>

            <p>Ihre Lohnsteuerabzugsmerkmale erheben wir über die beim Bundeszentralamt für Steuern (BZSt) geführte sog. ELStAM-Datenbank (Datenbank für Lohnsteuerabzugsmerkmale) </p>
            <div></div>

            <p><strong>An welche Empfänger werden die Daten weitergegeben?</strong></p>
            <div></div>

            <p>Wir geben Ihre personenbezogenen Daten innerhalb unseres Unternehmens ausschließlich an die Bereiche und Personen weiter, die diese Daten zur Erfüllung der vertraglichen und gesetzlichen Pflichten bzw. zur Umsetzung unseres berechtigten Interesses benötigen (z.B. die Buchhaltungsabteilung oder andere Fachabteilungen im Rahmen eines Einsatz- oder Positionswechsels).</p>
            <div></div>
            <p>Wir übermitteln Ihre Daten nur dann an Empfänger außerhalb des Unternehmens, soweit gesetzliche Bestimmungen dies erlauben oder gebieten, die Weitergabe zur Abwicklung und somit zur Erfüllung des Honorarvertrages erforderlich ist, uns Ihre Einwilligung vorliegt oder wir zur Erteilung einer Auskunft befugt bzw. verpflichtet sind. Unter diesen Voraussetzungen können Empfänger personenbezogener Daten z. B. sein: </p>
            <div></div>

            <p>Empfänger, an die die Weitergabe zur Vertragsbegründung bzw. Vertragserfüllung bzw. soweit die Weitergabe für das Beschäftigungsverhältnis unmittelbar erforderlich ist, wie z.B. Banken und Steuerberater zur Berechnung und Auszahlung von Lohn- und Gehalt</p>
            <div></div>
            <p>Öffentliche Stelle und Institutionen, wie z.B. Sozialversicherungsträger und Finanzämter zur Befolgung unserer gesetzlichen Pflichten. </p>
            <div></div>
            <p>Weitere Empfänger, für die Sie uns Ihre Einwilligung zur Datenübermittlung erteilt haben, z.B. private Krankenkassen.</p>
            <div></div>

            <p>Gegebenenfalls geben wir Ihre Daten auch an Dienstleister weiter, die uns z. B. in den Bereichen EDV oder der Archivierung und Vernichtung unterstützen und mit denen gesonderte Verträge zur Auftragsverarbeitung geschlossen wurden. Durch den Abschluss entsprechender Verträge stellen wir sicher, dass die Verarbeitung Ihrer personenbezogenen Daten im Einklang mit den datenschutzrechtlichen Bestimmungen erfolgt.</p>
            <div></div>

            
            <p><strong>Wo werden die Daten verarbeitet?</strong></p>
            <div></div>
            <p>Die Daten werden grundsätzlich auf dedizierten IT-Systemen in unseren Räumlichkeiten verarbeitet. Auf diese IT-Systeme haben neben Administratoren nur Mitglieder der Finanzbuchhaltung und der Unternehmensleitung Zugriff.</p>
            <div></div>
            <p>Sollten Ihre abrechnungsrelevanten Daten bei Dienstleistern verarbeitet werden, stellen wir sicher, dass dies unter Einhaltung der datenschutzrechtlichen Vorgaben erfolgt. Eine Verarbeitung Ihrer abrechnungsrelevanten Daten außerhalb der Europäischen Union erfolgt nicht.</p>
            <div></div>


            <p><strong>Ihre Rechte als „Betroffene“ </strong></p>
            <div></div>


            <p>Sie haben das Recht, auf Antrag unentgeltlich Auskunft über die zu Ihrer Person gespeicherten personenbezogenen Daten zu erhalten (Art. 15 Abs. 1 DSGVO). Zusätzlich haben Sie bei Vorliegen der gesetzlichen Voraussetzungen ein Recht auf Berichtigung (Art. 16 DSGVO), Löschung (Art. 17 DSGVO) und Einschränkung der Verarbeitung (Art. 18 DSGVO) Ihrer personenbezogenen Daten sowie auf Datenübertragbarkeit (Art. 20 DSGVO).</p>
            <div></div>

            <p>Sie haben das Recht, Ihre Einwilligung jederzeit mit Wirkung für die Zukunft zu widerrufen, wenn die Daten auf Grundlage von Art. 6 Abs. 1 S. 1 lit. a oder Art. 9 Abs. 2 lit. a DSGVO verarbeitet werden. Bitte richten Sie den Widerruf an: office@datenschutz-nord.de. </p>
            <div></div>


            <p>Sie haben das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die Datenverarbeitung zu erheben, wenn die Daten auf Grundlage von Art. 6 Abs. 1 S. 1 lit. e oder f DSGVO verarbeitet werden. Bitte richten Sie den Widerspruch an: office@datenschutz-nord.de.</p>
            <div></div>

            <p>Sie haben gem. Art. 77 DSGVO das Recht Beschwerde bei einer Datenschutzaufsichtsbehörde einzureichen.</p>
            <div></div>


            <p>Unseren Datenschutzbeauftragten (datenschutz nord GmbH) erreichen Sie über office@datenschutz-nord.de. </p>
            <div></div>


            <p>Bitte geben Sie bei Anfragen an unseren Datenschutzbeauftragten einen der oben genannten Verantwortlichen an.</p>
            <div></div>



            </div>

    </div>

</div>

{/if}