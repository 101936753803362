<script>

    import { settings } from "../../stores/settings";

    export let pageNumber = 1;
    export let isEnd = false;
    export let callback;

    /////////////////////////////////////////////////////////////////////////////////////////////////////////
   
    const handleClick = () => {
        callback(pageNumber);
    }

    const handleKey = () => {
    }

</script>


<div class="daa-icon-page" 
    on:click={handleClick}
    on:keyup={handleKey}
    role="none">

    {#if $settings.page === pageNumber}
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 30 40" style="enable-background:new 0 0 30 40;" xml:space="preserve">
            <style type="text/css">
                .st0{fill:#AAE5A3;}
                .st1{fill:#068718;}
                </style>
            <g id="BG">
                <polygon class="st0" points="21.2,0 0,0 0,40 30,40 30,8.8 	"/>
            </g>
            <g id="Dock">
                <path class="st1" d="M21.2,0H0v40h30V8.8L21.2,0z M21,1.3L28.7,9H21V1.3z M1,39V1h19v9h9v29H1z"/>
            </g>
        </svg>

        <div class="daa-icon-page-number daa-icon-page-number-color">{(!isEnd)?pageNumber:"E"}</div>

    {:else}

        <svg version="1.1" id="iconPage" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 30 40" xml:space="preserve">
            <path fill="rgb>(255,0,0)" d="M21.2,0H0v40h30V8.8L21.2,0z M21,1.3L28.7,9H21V1.3z M1,39V1h19v9h9v29H1z"/>
        </svg>

        <div class="daa-icon-page-number">{(!isEnd)?pageNumber:"E"}</div>

    {/if}

    
</div>


