import { writable } from 'svelte/store';

export const sendDocs = writable({

    sendToEMail: "dozenten@daa-technikum.de",
    sendToName: "DAA-Technikum",
    sendToStreet: "Auf der Union 10",
    sendToCity: "45141 Essen",

    "privateHealthInsurance":{
        title: "Privat versichert",
        text: "Die Bestätigung Ihrer privaten Krankenversicherung über den Gesamtbeitrag",
        text2: false,
        legal: "(§ 257 SGB V und § 61 SGB XI)",
    },
    "children":{
        title: "Kinder",
        text: "Nachweis (z. B. Geburtsurkunde). Geburtsurkunden sind nur erforderlich, wenn Sie von uns zukünftig sozialversicherungspflichtig beschäftigt werden (oberhalb der Minijob-Grenzen) und nicht privat versichert sind.",
        text2: false,
        legal: false,
    },

    "Gewährleistungserstreckungsbescheid": {
        title: "Beamtin/er – Pension",
        text: "Gewährleistungserstreckungsbescheid (nach §5 SGB VI)",
        text2: false,
        legal: false,
    },

    "Versorgungswerk": {
        title: "Ich bin in einem Versorgungswerk versichert",
        text: "Nachweis (z.B. Beitragsbescheinigung)",
        text2: false,
        legal: false,
    },

    "Immatrikulationsbescheinigung": {
        title: "Ich bin Student/in",
        text: "Die Immatrikulationsbescheinigung",
        text2: false,
        legal: false,
    },

    "Rentenbescheid": {
        title: "Ich bin bei der Deutschen Rentenversicherung versichert",
        text: "Rentenbescheid",
        text2: false,
        legal: false,
    }


});
