<script>

    export let label = "Label";
    export let active = false;
    export let callback;

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const handleClick = () => {
        callback();
    } 

    
</script>

{#if active }

    <button on:click= {handleClick} class="daa-button daa-button-ok">{label}</button>

{:else }

    <button class="daa-button daa-button-inactive">{label}</button>

{/if}
