<script>

    // @ts-nocheck
    import { settings } from '../../stores/settings.js';
    import { endpoints } from '../../stores/endpoints.js';
    import { user } from '../../stores/user.js';
    import { modal } from "../../stores/modal";  
    import AdminBar from './AdminBar.svelte';
    import AdminVUBar from './AdminVUBar.svelte';
    import Table from '../table/Table.svelte';
    import TableRow from '../table/TableRow.svelte';
    import TableAdminUserView from '../table/TableAdminUserView.svelte';
    import Footer from '../footer/Footer.svelte';
    import ModalHint from '../modal/ModalHint.svelte';
    import ModalDelete from '../modal/ModalDelete.svelte';
    import ModalImpressum from '../modal/ModalImpressum.svelte';

    $:currentCell = $settings.userSortBy;
    $:showHintUnsuccess = false;
    $:showHintSuccess = false;

     
    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const getHeadCellClass = (str, curr) => {
        if (curr === str) {
            return "daa-table-head-cell-sortable-active";
        }
        return "daa-table-head-cell-sortable";
    }

    const handleClickHeadCell = (e) => {
        if(e.target.id === $settings.userSortBy) {
            $settings.userSortDirection === "ASC" ? $settings.userSortDirection = "DESC" : $settings.userSortDirection = "ASC";
        }
        else {
            $settings.userSortDirection = "ASC";
            $settings.userSortBy = e.target.id;
        }
        currentCell = e.target.id;
    }

    const handleModalDelete = (str) => {
        $modal.current = "none";
        console.log("about to delete")
        
    }

    const handleAdminBarLoadUsers = (str) => {
        if (str === "uploadSuccess") {
            loadUsers ();
        }
    }

    async function loadUsers () {

        const params = '/?page=1'
        const response = await fetch($endpoints.EPpath+$endpoints.EPUserList+params, {
            method:"GET",
            headers: {
                "Content-Type": "application/json",
                'Accept':'application/json',
                'Authorization': 'Bearer '+$settings.token
            },
        });

        const resp = await response.json();

        console.log ("user loading response is", resp);

        if (resp.status === false) {
            handleUnsuccess();

        } else {
            handleSuccess ()
        }
    }

    const handleUnsuccess = () => {  
        showHintUnsuccess = true;
    }

    const handleSuccess = () => {  
        console.log("User loading should have worked successfully");
        showHintUnsuccess = false;
    }


</script>


<AdminVUBar callback={handleAdminBarLoadUsers}></AdminVUBar>

<div class="daa-main-layout">

    <div></div>

    <div class="daa-adm-1-col">

        <!-- table head -->
        <div class="daa-table-head daa-table-admin-user-cols" role="none">

            <div class="daa-table-cell">Daten</div>
            <div class="daa-table-cell">Details</div>
            <div></div>
        </div>

        {#if showHintUnsuccess} 
            <div class="daa-no-data-loaded">No data loaded</div>
        {:else}
            <TableAdminUserView></TableAdminUserView>
        {/if}
      
    </div>

    <div></div>

 </div>

 <div style="height: 20px;"></div>

<Footer positionFix="true"></Footer>
<ModalImpressum></ModalImpressum>
<ModalDelete callback={handleModalDelete}></ModalDelete>

<!-- <ModalHint></ModalHint> -->
