<script>

    // @ts-nocheck
    import IconPage from "../icon/IconPage.svelte";

    export let callback;

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const handleChangePage = (nr) => {
        callback(nr);
    }

</script>
  
<div class="daa-main-layout">
    <div></div>
    <div class="daa-pages-indicator">
        <div style = "margin-left:auto"></div>
        <div style="transform:translateY(-4px);">Seiten:</div>
        <IconPage pageNumber = {1} callback={handleChangePage}></IconPage>
        <IconPage pageNumber = {2} callback={handleChangePage}></IconPage>
        <IconPage pageNumber = {3} callback={handleChangePage}></IconPage>
        <IconPage pageNumber = {4} callback={handleChangePage}></IconPage>
        <IconPage pageNumber = {5} callback={handleChangePage}></IconPage>
        <IconPage pageNumber = {6} callback={handleChangePage} isEnd={true}></IconPage>

    </div>

    <div></div>

</div>