<script>

    // @ts-nocheck
    import { onMount } from 'svelte';  
    import { sendDocs } from '../../stores/sendDocs.js';
    import Icon from '../icon/Icon.svelte';

    export let key;
   
    $:item = $sendDocs[key];

    console.log("item in SendDocsItem is", item);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const startValidation = () => {
    }
   
    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    export const validatePanel = () => {
    };

    export const update = () => {
       
    }

    onMount(async () => {
        update();
	});

    ////////////////////////////////////////////////////////////////////////////////////////////////////////

</script>

<div class="daa-send-doc-item">
    <!-- <div class="daa-send-doc-item-headline">
        <div class="daa-send-doc-item-headline-reason">Grund</div>
        <div class="daa-send-doc-item-headline-text">{item.title}</div>
    </div> -->

    <div class="daa-send-doc-item-text">
        <div class= "daa-send-doc-item-text-left ">
            <div style="width:22px;">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 15 20" style="enable-background:new 0 0 15 20;" xml:space="preserve">
                <style type="text/css">
                    .st0{fill:#FFFFFF;}
                    .st1{fill:#9D9E9D;}
                </style>
                <g id="doc_x5F_small_x5F_bg">
                    <polygon class="st0" points="10.4,0 0,0 0,20 15,20 15,4.5 	"/>
                </g>
                <g id="doc_x5F_small">
                    <path class="st1" d="M10.4,0H0v20h15V4.5L10.4,0z M10.3,0.9l3.7,3.7h-3.7V0.9z M0.7,19.3V0.7h8.9v4.6h4.6v13.9H0.7z"/>
                </g>
                </svg>
            </div>
        </div>
        <div>
            <div class="daa-send-doc-item-text-right">{item.title}</div>
            <div class="daa-send-doc-item-text-right-normal">{item.text}</div>
            {#if item.text2}
                <div>{item.text2}</div>
            {/if}
        </div>

    </div>

   

</div>





