import { writable } from 'svelte/store';

export const admins = writable([

    {"id":11, "firstName":"Bettina", "lastName":"Nachnamiger", "eMail":"", "company":"DAA Technikum"},
    {"id":22, "firstName":"Rüdiger", "lastName":"Ratlos", "company":"DAA Andere Firma"},
    {"id":333, "firstName":"Sylvia", "lastName":"Schönfeld-Artig", "company":"DAA Technikum"},
    {"id":4444, "firstName":"Otto", "lastName":"Normalverbraucher", "company":"Was völlig anderes"},

]);

export const currentAdminId = writable({
    "id": -99999,
});
