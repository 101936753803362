<script>

    // @ts-nocheck
    import { onMount } from 'svelte';  
    import { settings } from '../../stores/settings.js';
    import { endpoints } from '../../stores/endpoints.js';
    import { user } from '../../stores/user.js';
    import { admins, currentAdminId } from '../../stores/admins.js';
    import { modal } from "../../stores/modal";  
    import AdminsBar from './AdminsBar.svelte';
    import AdminVUBar from './AdminVUBar.svelte';
    import Table from '../table/Table.svelte';
    import TableRow from '../table/TableRow.svelte';
    import TableAdmins from '../table/TableAdmins.svelte';
    import Footer from '../footer/Footer.svelte';
    import ModalCreateAdmin from '../modal/ModalCreateAdmin.svelte';
    import ModalEditAdmin from '../modal/ModalEditAdmin.svelte';
    import ModalDelete from '../modal/ModalDelete.svelte';
    import ModalDeleteAdmin from '../modal/ModalDeleteAdmin.svelte';
    import ModalImpressum from '../modal/ModalImpressum.svelte';

    $:currentCell = $settings.userSortBy;
    $:showHintUnsuccess = false;
    $:showHintSuccess = false;

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const getHeadCellClass = (str, curr) => {
        if (curr === str) {
            return "daa-table-head-cell-sortable-active";
        }
        return "daa-table-head-cell-sortable";
    }

    const handleClickHeadCell = (e) => {
        if(e.target.id === $settings.userSortBy) {
            $settings.userSortDirection === "ASC" ? $settings.userSortDirection = "DESC" : $settings.userSortDirection = "ASC";
        }
        else {
            $settings.userSortDirection = "ASC";
            $settings.userSortBy = e.target.id;
        }
        currentCell = e.target.id;
    }

    const handleModalCreateAdmin = (str) => {
        $modal.current = "none";
    }

    const handleModalEditAdmin = (str) => {
        $modal.current = "none";
    }

    const handleModalDeleteAdmin = (str) => {
        $modal.current = "none";
        if (str === "success") {
            $currentAdminId.id = -99999;
            loadAdmins ();
        }
    }

    const handleAdminBarLoadUsers = (str) => {
        if (str === "uploadSuccess") {
            loadUsers ();
        }
    }

    //////////////////////////////// create admin //////////////////////////////////////////////////////////

    
    //////////////////////////////// load admins ///////////////////////////////////////////////////////////

    async function loadAdmins () {

        const response = await fetch($endpoints.EPpath+$endpoints.EPAdmins, {
            method:"GET",
            headers: {
                "Content-Type": "application/json",
                'Accept':'application/json',
                'Authorization': 'Bearer '+$settings.token
            },
        });

        const resp = await response.json();
    
        if (resp.status === false) {
            handleUnsuccess();

        } else {
            handleSuccess (resp.data)
        }
    }

    const handleUnsuccess = () => {  
        showHintUnsuccess = true;
    }

    const handleSuccess = (responseData) => {  
        if (responseData) {
            console.log("about to set admins in store");
           
            $admins = {...responseData};
            //showHintUnsuccess = false;
        }
    }

    onMount(async () => {
	     loadAdmins();
	});



</script>


<AdminsBar callback={handleAdminBarLoadUsers}></AdminsBar>

<div class="daa-main-layout">

    <div></div>

    <div class="daa-adm-1-col">

        <!-- table head -->
        <div class="daa-table-head daa-table-admins-cols" role="none">
            <div id="admin"  class="daa-table-cell">id</div>
            <div class="daa-table-double-cell-head">
                <div id="firstName"  class="daa-table-cell">Vorname</div>
                <div id="lastName"  class="daa-table-cell">Nachname</div>
            </div>
            <div id="keyVal"  class="daa-table-cell">Firma</div>
            <div id="status" class="daa-table-cell" >Aktion</div>
            <div></div>
        </div>

        {#if showHintUnsuccess} 
            <div class="daa-no-data-loaded">No data loaded</div>
        {:else}
            <TableAdmins></TableAdmins>
        {/if}
      
    </div>

    <div></div>

 </div>

 <div style="height: 20px;"></div>

<Footer positionFix="true"></Footer>
<ModalImpressum></ModalImpressum>
<ModalCreateAdmin callback={handleModalCreateAdmin}></ModalCreateAdmin>
<ModalEditAdmin callback={handleModalEditAdmin}></ModalEditAdmin>
<ModalDeleteAdmin callback={handleModalDeleteAdmin}></ModalDeleteAdmin>

<!-- <ModalHint></ModalHint> -->
