<script>

    import { onMount, onDestroy } from 'svelte'; 
    import { settings } from '../../stores/settings.js';
    import { validateDayMonthYear } from '../helper/validate.js';

    export let data;
    export let value;
    export let store;
    export let fieldName = "none";
    export let labelClass = "daa-label-w400";

    $:dateObj = {day:"", month:"", year:""};
    $:showWarning = false;
    $:checkSilently = false;

    let dayField;
    let monthField;
    let yearField;

    
    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const handleInput = (e) => {
        showWarning = false;
        $settings.showWarningInFooter = false;
    }

    const valuesToString = () => {
        let arr = [dayField.value,".", monthField.value, ".", yearField.value];
        let joined = arr.join("");
        let stripped = joined.replace(/" "/g, "");
        if (stripped === "..") return "";
        return stripped;
    }

    const stringToValues = (str) => {
        const arr = str.split(".");
        if (arr.length !== 3) {
            dayField.value = "";
            monthField.value = "";
            yearField.value = "";
        }
        else {
            dayField.value = arr[0];
            monthField.value = arr[1];
            yearField.value = arr[2];
        }
    }


    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    export function validate (boolSilent) {

        checkSilently = !boolSilent;

        let rules = data.validate;
        if (!rules) return "valid";
        let checkDate = validateDayMonthYear (dayField.value, monthField.value, yearField.value, rules);
        if(checkDate.valid !=="valid") {
            if (data.required) {
                showWarning = true;
            }
            else {
                let added = dayField.value + monthField.value + yearField.value;
                let stripped = added.replace(/" "/g, "");
                if(stripped === "") {
                    checkDate.valid ="reset";
                    return;
                }
                showWarning = true;
            }
        }
        return checkDate.valid;
    }

    export const getValue = () => {
        let obj = {};
        obj[fieldName] = valuesToString();
        return obj;
    };

   
    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    export const update = () => {
        let dateString = store[fieldName];
        console.log("update date birthDay", dateString)
        stringToValues(dateString);
    };

    const saveToStore = () => {
        store[fieldName] = valuesToString();
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    onMount(async () => {
        stringToValues(value);
    });

    onDestroy(() => {
        saveToStore();
    });

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

</script>


<div class="daa-input-grid {labelClass}">

    <div class="daa-label-container">
        <label class="daa-input-label daa-fnt-std" for="myInput">{data.name}</label>
        {#if data.required} 
            <div class="daa-hint-asterix">*</div> 
        {/if}
    </div>

    <div class="daa-date-container">

        <input type="text" 
            bind:this = {dayField}
            on:input={handleInput}
            class="daa-input-date daa-fnt-std" 
            style="width:36px; text-align:center; {(showWarning === false ) ? "": "border: 1px solid red;"}"
            maxlength="2"
        >

        <input type="text" 
            bind:this = {monthField}
            on:input={handleInput}
            class="daa-input-date daa-fnt-std" 
            style="width:36px; text-align:center; {(showWarning === false ) ? "": "border: 1px solid red;"}"
            maxlength="2"
        >

        <input type="text" 
            bind:this = {yearField}
            on:input={handleInput}
            class="daa-input-date daa-fnt-std" 
            style="width:54px; text-align:center; {(showWarning === false ) ? "": "border: 1px solid red;"}"
            maxlength="4"
        >

        <div class="daa-date-hint">(Tag | Monat | Jahr)</div>

    </div>

</div>


<!-- Error -->
{#if showWarning}

<div class="daa-input-grid {labelClass}">
    <div></div> 
    <div class="daa-warning">{data.error}</div>
</div>

{/if}
