<script>

    // @ts-nocheck

    import { onMount, onDestroy } from 'svelte'; 
    import { fields } from '../../stores/form.js';
    import { settings } from '../../stores/settings.js';
    import { validateChars, validateSSN , getBirtdateFromSSN, noSpace } from '../helper/validate.js';
    import HelpButton from '../helpTextBox/HelpButton.svelte';
    import { userObj } from '../../stores/userObj.js';

    export let fieldName = "none";
    export let value = "";
    export let store;
    export let labelClass = "daa-label-w400";
    export let callback = function(str) {};
   
    let data = $fields[fieldName];
    let inputField;
    
    $:currentValue = value;
    $:showWarning = false;
    $:checkSilently = false;


    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const handleInput = (e) => {

        if(fieldName === "taxId") {
            console.log("taxId", e.currentTarget.value);
            currentValue = noSpace(e.currentTarget.value);
        }

        saveToStore();
        showWarning = false;
        $settings.showWarningInFooter = false;
        callback (currentValue);
    }

   /////////////////////////////////////////////////////////////////////////////////////////////////////////

    export function validate (boolSilent) {

        checkSilently = !boolSilent || false

        let rules = data.validate;
        if (!rules) return "valid";
        if (rules.length == 0) return "valid";

        
        // special case SNN:
        if (rules[0]=="SSN") {
            // validate the number 
            let chSSN = validateSSN(currentValue);
            if(chSSN === "valid") {
                // get birthday from SNN:
                let strBirthday = getBirtdateFromSSN(currentValue);
                // if no birthday present, set it now:
                if (!store.birthDate || store.birthDate == "") {
                    store.birthDate = strBirthday;
                }
                return "valid";
            } else {
                console.log("rules contain SSN, invalid");
                showWarning = true;
                return "invalid";
            }
        } else {
            // normal cases:
            let checkChars = validateChars (currentValue, rules);
            if(checkChars !=="valid") showWarning = true;
            return checkChars;

        }
    }

    export const setValue = (val) => {
        currentValue = val;
        store[fieldName] = currentValue;

    };

    export const getValue = () => {
        return currentValue;
    };

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    export const update = () => {
        currentValue = store[fieldName];
    };

    const saveToStore = () => {
        store[fieldName] = currentValue;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    onMount(async () => {

    });

    onDestroy(() => {
        saveToStore();
    });

    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    
</script>


<div class="daa-input-grid {labelClass}">

    <div class="daa-label-container">
        <label class="daa-input-label daa-fnt-std" for={fieldName}>{data.name}</label>
        {#if data.required} 
            <div class="daa-hint-asterix">*</div> 
        {/if}
    </div>
    
    <div class="daa-input-text-hint-container">
        <input 
            class="daa-input-text daa-fnt-std" 
            style={(showWarning === false ) ? "": "border: 1px solid red;"}
            type="text" 
            id={fieldName} 
            name={fieldName} 
            bind:this={inputField}
            bind:value={currentValue}
            on:input={handleInput}> 

        <HelpButton show={data.useHelp} ref={fieldName}></HelpButton>
       
    </div>
       
</div>


<!-- Error -->
{#if showWarning}

<div class="daa-input-grid {labelClass}">
    <div></div> 
    <div class="daa-warning">{data.error}</div>
</div>

{/if}

