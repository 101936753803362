import { writable } from 'svelte/store';

export const helptext = writable({

    "firstName": `<div class="daa-help-box-headline">Vorname</div>
                  <div class="daa-help-box-text">Ihr Vorname</div`,

    "somethingDifferent": `<div class="daa-help-box-headline">Erläuterung</div>
                  <div class="daa-help-box-text">Sie sind also nicht bei einem Versorgungswerk</div>`,

    "taxId": `<div class="daa-help-box-headline">Steuer-Identifikationsnummer</div>
            <div class="daa-help-box-text">Diese Nummer besteht aus 11 Zeichen.</div>
            <div class="daa-help-box-text">Sie können sie z.B. auf Ihrer Lohnsteuerkarte oder Ihrem Einkommensteuerbescheid finden.</div>`,
    
            "SSN": `<div class="daa-help-box-headline">Sozialversicherungsnummer</div>
                    <div class="daa-help-box-text">Die Sozialversicherungsnummer besteht aus 12 Zahlen und Buchstaben. An den Stellen 3 bis 8 befindet sich Ihr Geburtsdatum (TTMMJJ).</div>
                       <div class="daa-help-box-text">Sie können sie z.B. auf Ihrem Sozialversicherungsausweis oder Schreiben der Rentenkasse finden.</div>`,
    
    
    
});
