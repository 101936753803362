<script>

    import { onMount } from 'svelte';  
    import { settings } from '../../stores/settings.js';
    import DateOnly from './DateOnly.svelte';
    import { validate67 } from '../helper/validate.js';

    export let store;
    export let labelClass = "";

    const checkMissingData = () => {

        let check = true;
        if (!store.birthDate) check = false;
        if (store.birthDate === "") check = false;
        if (!store.healthInsuranceType) check = false;
        if (store.healthInsuranceType === "") check = false;

        $settings.allowTreeStart = check;
        return check;
    }

    $:isValid = checkMissingData();
    
    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    export function validate (boolSilent) {
        isValid = checkMissingData();
    };

    export const getValue = () => {
    };
    export const update = () => {
    };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////

    onMount(async () => {
	});

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

</script>

{#if !isValid}

<div class="daa-tree-subtype {labelClass}">
    <div></div>
    <div class="daa-tree-subtype-content">
       
        <div class="daa-hint-check67">
            <div class="daa-hint-check67-text daa-hint-check67-headline">
                Es fehlen noch ein paar Daten!
            </div>

            <div class="daa-hint-check67-text">
                Bitte füllen Sie die vorherige Seite aus und speichern sie.
            </div>
        </div>

    </div>

</div>

{/if}
