<script> 

    // @ts-nocheck 
    import { onMount } from 'svelte';  
    import { settings } from "../../stores/settings";
    import { modal } from "../../stores/modal";  
    import { endpoints } from "../../stores/endpoints";    
    import ButtonCancel from "../button/ButtonCancel.svelte";
    import ButtonOk from "../button/ButtonOk.svelte";

    export let show = false;
    export let callback;

    $:showHintUnsuccess = false;
    $:showHintSuccess = false;

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const handleCancel = () => {
        $modal.current = "none";
        show = false;
        callback ("cancel");
    }

    const handleLeave = () => {
        $modal.current = "none";
        show = false;
        callback ("cancel");
    }

    const handleOk = () => {
        exportFile();
    }

    ////////////////////////////////// export ///////////////////////////////////////////////////////////////

    async function exportFile() {
        const params = '/?groupsId='+$settings.groupId
        const response = await fetch($endpoints.EPpath+$endpoints.EPdownload+params, {
            method:"GET",
            headers: {
                "Content-Type": "application/json",
                'Accept':'application/json',
                'Authorization': 'Bearer '+$settings.token
            },
        });

        const resp = await response;
        const blobData = await resp.blob();

        if (resp.status === false) {
           // handleExportUnsuccess();

        } else {
           handleExportSuccess(blobData);
        }
    }

    const handleExportUnsucces = () => {  
        showHintUnsuccess = true;
    }

    const handleExportSuccess = (blobData) => {  
        if (blobData) {
            let now = new Date();
            const aElement = document.createElement("a");
            aElement.setAttribute("download", "hokra_"+now.toISOString()+".xlsx");
            const href = URL.createObjectURL(blobData);
            aElement.href = href;
            aElement.setAttribute("target", "_blank");
            aElement.click();
            URL.revokeObjectURL(href);
            handleLeave();
        }
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    onMount(async () => {
         
	});


</script>

{#if show}

<div class="daa-backdrop daa-backdrop-dark">

    <div class="daa-dialog-container">

        <div class="daa-modal-headline">Export (Excel)</div>

        <div class="daa-dialog-hint">

            <div class="daa-icon-download-container">

                <button class="daa-icon-download-btn" on:click={exportFile}>

            <svg version="1.1" id="icon_excel" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
            <style type="text/css">
                .st0{fill:#185C37;}
                .st1{fill:#21A366;}
                .st2{fill:#107C41;}
                .st3{opacity:0.1;enable-background:new    ;}
                .st4{opacity:0.2;enable-background:new    ;}
                .st5{fill:url(#SVGID_1_);}
                .st6{fill:#FFFFFF;}
                .st7{fill:#33C481;}
            </style>
            <path class="st0" d="M62.8,47.2l-39.5-7v51.6c0,2.4,1.9,4.3,4.3,4.3l0,0h68.2c2.4,0,4.3-1.9,4.3-4.3l0,0v-19L62.8,47.2z"/>
            <path class="st1" d="M62.8,3H27.5c-2.4,0-4.3,1.9-4.3,4.3l0,0v19l39.5,23.3l20.9,7l16.3-7V26.3L62.8,3z"/>
            <path class="st2" d="M23.3,26.3h39.5v23.3H23.3V26.3z"/>
            <path class="st3" d="M51.6,21.6H23.3v58.1h28.3c2.4,0,4.3-1.9,4.3-4.3V25.9C55.8,23.5,53.9,21.6,51.6,21.6z"/>
            <path class="st4" d="M49.2,23.9h-26v58.1h26c2.4,0,4.3-1.9,4.3-4.3V28.2C53.5,25.8,51.6,23.9,49.2,23.9z"/>
            <path class="st4" d="M49.2,23.9h-26v53.5h26c2.4,0,4.3-1.9,4.3-4.3v-45C53.5,25.8,51.6,23.9,49.2,23.9z"/>
            <path class="st4" d="M46.9,23.9H23.3v53.5h23.6c2.4,0,4.3-1.9,4.3-4.3v-45C51.2,25.8,49.3,23.9,46.9,23.9z"/>
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="8.888" y1="-1911.4006" x2="42.2747" y2="-1853.5762" gradientTransform="matrix(1 0 0 1 0 1932)">
                <stop  offset="0" style="stop-color:#18884F"/>
                <stop  offset="0.5" style="stop-color:#117E43"/>
                <stop  offset="1" style="stop-color:#0B6631"/>
            </linearGradient>
            <path class="st5" d="M4.3,23.9h42.6c2.4,0,4.3,1.9,4.3,4.3v42.6c0,2.4-1.9,4.3-4.3,4.3H4.3c-2.4,0-4.3-1.9-4.3-4.3V28.2
                C0,25.8,1.9,23.9,4.3,23.9z"/>
            <path class="st6" d="M13.2,63.4l9-13.9L14,35.7h6.6l4.5,8.8c0.4,0.8,0.7,1.5,0.9,1.9H26c0.3-0.7,0.6-1.3,0.9-2l4.8-8.8h6.1
                l-8.4,13.7l8.6,14h-6.5l-5.2-9.7c-0.2-0.4-0.5-0.8-0.6-1.3h-0.1c-0.2,0.4-0.4,0.9-0.6,1.3l-5.3,9.7L13.2,63.4L13.2,63.4z"/>
            <path class="st7" d="M95.7,3H62.8v23.3H100v-19C100,4.9,98.1,3,95.7,3L95.7,3z"/>
            <path class="st2" d="M62.8,49.5H100v23.3H62.8V49.5z"/>
            </svg>

            </button>
        </div>

        </div>

        <div class="daa-dialog-buttons-container">
            <ButtonCancel active={true} label="Abbrechen" callback={handleCancel}></ButtonCancel>
        </div>

    </div>

   

</div>

{/if}
