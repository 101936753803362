import { writable } from 'svelte/store';

export const admin = writable({

    "id": "",
    "username": "",
    "password": "",
    "firstName": "",
    "lastName": "",
    "eMail": "",
    "groupsId": "",
    "groupsName": ""

}
);

export const currentAdminId = writable({
    "id": -99999,
});


export const tempAdmin = writable({    
    "id": "",
    "username": "",
    "password": "",
    "firstName": "",
    "lastName": "",
    "eMail": "",
    "groupsId": "",
    "groupsName": ""
}
);

export const emptyAdmin = writable({

    "id": "",
    "username": "",
    "password": "",
    "firstName": "",
    "lastName": "",
    "eMail": "",
    "groupsId": "",
    "groupsName": ""
    
}
);

export const tempCreateAdmin = writable({

    "id": "",
    "username": "",
    "password": "",
    "firstName": "",
    "lastName": "",
    "eMail": "",
    "groupsId": "",
    "groupsName": ""
    
}
);

