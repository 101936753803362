
<script>

    import { onMount } from 'svelte';  
    import { settings } from '../../stores/settings.js'; 
    import { users } from '../../stores/users.js'; 
    import { currentUserId } from '../../stores/user.js';
    import { modal } from "../../stores/modal";  
    import Icon from "../icon/Icon.svelte";
    import IconButton from "../icon/IconButton.svelte";
    import TablePagination from './TablePagination.svelte';

    export let callback;

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const handlePaginationClick = (pageInt) => {
        callback(pageInt);
    }

    const handleTrashIconClicked = (idStr) => {
        if(idStr) $currentUserId["id"] = idStr;
        $modal.current = "deleteUser";
    }

    const handleEyeIconClicked = (idStr) => {
        if(idStr) $currentUserId["id"] = idStr;
        $settings.view = "adminviewuser";
    }

</script>


<div class="daa-table-container">

 {#each $users as user}

<div class="daa-table-row daa-table-user-cols" role="none">
    
    <div class="daa-table-cell">{user.employeeID}</div>

    <div class="daa-table-double-cell">
        <div class="daa-table-cell">{user.firstName}</div>
        <div class="daa-table-cell">{user.lastName}</div>
    </div>

    <div class="daa-table-cell">{user.update_at || ""}</div>

    <div class="daa-table-cell">
        <div class="daa-icon">
            {#if user.status && user.status === "1"}
                <Icon type="ok"></Icon>
            {:else}
            <div></div>
            {/if}
        </div>
     
    </div>
    <div class="daa-table-cell-icons">
        <IconButton id={user.id} type="trash" callback={handleTrashIconClicked}></IconButton>
        <IconButton id={user.id} type="eye" callback={handleEyeIconClicked}></IconButton>
    </div>
    
</div>

{/each}

</div>

<div class="daa-table-foot" >
    <TablePagination callback={handlePaginationClick}></TablePagination>
    {$settings.pagination.total}
</div> 
