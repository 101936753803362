<script>

    import { fields } from '../../stores/form.js';
    import Icon from '../icon/Icon.svelte';
 
    export let fieldName = "none";
    export let labelClass = "daa-label-w300";
   
    let text = $fields[fieldName].text;
   
    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    
    export const validate = (bool) => {
        return "valid";
    };

    export const getValue = () => {
        return "";
    };

    export const update = () => {
    };


</script>


<div class="daa-input-grid {labelClass}">

    <div class="daa-label-container"></div>
    
    <div class="daa-input-text-hint-documents-container">
        <div style="width:22px;">
            <Icon type="sendDoc"></Icon>
        </div>
        <div style="padding-top:3px; font-style:italic">{text}</div>
        <div></div>
       
    </div>
       
</div>
