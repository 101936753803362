<script>

    // @ts-nocheck

    import { onMount } from 'svelte';  
    import { settings } from '../../stores/settings.js';
    import { minChars } from '../helper/validate.js';
    import { pages, panels, fields } from '../../stores/form.js';
    import { users } from '../../stores/users.js';
    import { options } from '../../stores/options.js';
    import { findInObjectArrayByKey } from '../helper/helper.js';
    import { user, dummyUser, currentUserId } from '../../stores/user.js';
    import { userObj } from '../../stores/userObj.js';
    import { endpoints } from '../../stores/endpoints.js';
    import ButtonOk from '../button/ButtonOk.svelte';
    import InputSelect from '../inputSelect/InputSelect.svelte';
    import InputText from '../inputText/InputText.svelte';
    import InputDate from '../inputDate/InputDate.svelte';
    import RadioButtonGroup from '../radio/RadioButtonGroup.svelte';
    import PagesIndicator from '../page/PagesIndicator.svelte';
    import Page from '../page/Page.svelte';
    import Panel from '../panel/Panel.svelte';
    import Thankyou from './Thankyou.svelte';
    import Footer from '../footer/Footer.svelte';
    import ModalImpressum from '../modal/ModalImpressum.svelte';
    import ModalDisclaimer from '../modal/ModalDisclaimer.svelte';
    import { validation } from '../../stores/validation.js';

    let inputMail, inputPwd;
    let panelComponents = {};
    let pageComponents = {};
    let page;
    $:pageNumber = $settings.page;
    $:btnActive = true;
    $:generateData = false;

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const handleChangePage = (nr) => {

        if($settings.page !== nr) {
            $settings.page = nr;
        }
    }

    const handleOk = () => {

        let allPannelsOk = true;

        for (let [key, component] of Object.entries(panelComponents)) {
            component.valuesToStore();
            let panelValid = component.validatePanel();
            if (panelValid === false) {
               // allPannelsOk = false
            }
        }
      
        if (allPannelsOk) saveUser();
    }

    const generateDummyValues = () => {
    }

    ////////////////////////////////// save user ////////////////////////////////////////////////////////////

    async function saveUser () {

       // const id = $currentUserId["id"];
        const response = await fetch($endpoints.EPpath+$endpoints.EPUserSave, {
            method:"POST",
            headers: {
                "Content-Type": "application/json",
                'Accept':'application/json',
                'Authorization': 'Bearer '+$settings.token
            },
            body: JSON.stringify(userObj)
        });

        const resp = await response.json();

        // console.log("save user response:::", resp);

        if (!resp.ok) {
            console.log("save NOT successfull")
            //handleUnsuccess();
        } else {
            //handleSuccess();
        }

    }

    const assignToObj = (respData) => {

        Object.keys(respData).forEach(key => {
            let value = respData[key];
            if(!value) value ="";
            userObj[key] = value;
        });

        // if(!userObj["tree"])  userObj["tree"] = "P";
        // if(!userObj["Mini"])  userObj["tree"] = "Mini";
        // if(!userObj["SSNAvailable"])  userObj["SSNAvailable"] = "";

        if(userObj["birthCountry"] === "") {
            userObj["birthCountry"] = "Deutschland";
            userObj["birthCountryCode"] = "D";
        }

        if(userObj["countryName"] === "") {
            userObj["countryName"] = "Deutschland";
            userObj["countryCode"] = "D";
        }

        if(userObj["nationality"] === "") {
            userObj["nationality"] = "deutsch";
            userObj["nationalityCode"] = "D";
        }

    }
    
    ////////////////////////////////// load  user ////////////////////////////////////////////////////////////

    async function loadUser () {

        const id = $currentUserId["id"];
        const response = await fetch($endpoints.EPpath+$endpoints.EPUser+"/"+id+"/detail", {
            method:"GET",
            headers: {
                "Content-Type": "application/json",
                'Accept':'application/json',
                'Authorization': 'Bearer '+$settings.token
            },
        });

        const resp = await response.json();

        if (resp.status === false) {
            handleUnsuccess();

        } else {
            handleSuccess (resp.data)
        }
    }

    const handleUnsuccess = () => {  
        console.log("load single user not successful")
    }

    const handleSuccess = (responseData) => {  
        if (responseData) {
            //console.log("user loaded, before userObj", responseData);
            assignToObj(responseData);
            update();
        }
    }

    const update = () => {

        if(pageComponents["page1"]) {
            pageComponents["page1"].update();
        }
        else {
            console.log("page 1 does not exit!!!")
        }

    }

    onMount(async () => {
	     loadUser();
	});


</script>


<div style="height:20px;"></div>

<!-- page icons -->
<PagesIndicator callback={handleChangePage}></PagesIndicator>

<!-- current page -->
<div class="daa-main-layout">
    <div></div>
    <div>
        {#if !generateData}

            {#if $settings.page === 1}
                <Page id={1}  bind:this={pageComponents["page1"]}></Page>
            {/if}
            {#if $settings.page === 2}
                <Page id={2}  bind:this={pageComponents["page2"]}></Page>   
            {/if}
            {#if $settings.page === 3}
                <Page id={3}  bind:this={pageComponents["page3"]}></Page>
            {/if}
            {#if $settings.page === 4}
                <Page id={4}  bind:this={pageComponents["page4"]}></Page>
            {/if}
            {#if $settings.page === 5}
                <Page id={5}  bind:this={pageComponents["page5"]}></Page>
            {/if} 
            {#if $settings.page === 6}
                <Thankyou id={6}  bind:this={pageComponents["page6"]}></Thankyou>
            {/if}
        {:else}
        <div>Creating data...</div>

        {/if}
        
      

    </div>

    <div></div>

 </div>

 <div style="height: 100px;"></div>

<Footer positionFix="false"></Footer>
<ModalImpressum></ModalImpressum>
<ModalDisclaimer></ModalDisclaimer>
