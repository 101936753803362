<script>

    // @ts-nocheck

    import { onMount, onDestroy } from 'svelte';  
    import { fade, fly } from 'svelte/transition';
    import { fields } from '../../stores/form.js';
    import { userObj } from '../../stores/userObj.js';
    import { settings } from '../../stores/settings.js';
    
    import InputSelect from '../inputSelect/InputSelect.svelte';
   

    // export let fieldName = "none";
    // export let value = "";
    export let store;
    export let labelClass = "daa-label-w400";

    let inputAdditionalOccupation;
    let inputTaxClass;
    $: valAdditional = "";
    $: showInputTaxClass = false;
   

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const handleSelectAdditional = (val) => {
        valAdditional = val;
        showInputTaxClass = (parseInt(val) === 0); // 0 => nein
    }

    const handleSelectTaxClass = (val) => {
    }

    const checkIfShowTaxClass = () => {
       
        if (store.additionalOccupation === "Nein") {
            showInputTaxClass = true;
        } else {
            showInputTaxClass = false;
        }
        
    }

    const getTaxClassStyle = () => {
        if (!showInputTaxClass) return "display:none;";
        return "";
    }


    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    export function validate (boolSilent) {

        let checkAdditional = inputAdditionalOccupation.validate();
        if (checkAdditional !== "valid") return "invalid";

        if (valAdditional === "0") {
            let checkTaxClass = inputTaxClass.validate();
            if (checkTaxClass !== "valid") return "invalid";
        }
        return "valid";
    }

    export const getValue = () => {
    };

    export const update = () => {
        inputAdditionalOccupation.update();
        inputTaxClass.update();
    };

    const saveToStore = () => {
    }
    
    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    onMount(async () => {
        checkIfShowTaxClass();
        update();
	});

    onDestroy(() => {
        // saveToStore();
    });

    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    
</script>

<InputSelect bind:this={inputAdditionalOccupation} 
    value = {store["additionalOccupation"]}
    data={$fields["additionalOccupation"]} 
    fieldName="additionalOccupation"
    store = {store}
    labelClass={labelClass}
    callback={handleSelectAdditional}
    >
</InputSelect>

<!-- {#if showInputTaxClass} -->
<div style={getTaxClassStyle(showInputTaxClass)}>
    
<InputSelect bind:this={inputTaxClass} 
    value = {store["taxClass"]}
    data={$fields["taxClass"]} 
    fieldName="taxClass"
    store = {store}
    labelClass={labelClass}
    callback={handleSelectTaxClass}
    >
</InputSelect>
</div>

<!-- {/if} -->
