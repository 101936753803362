<script>

    import { onMount, onDestroy } from 'svelte';     
    import { fade, fly } from 'svelte/transition';
    import { fields } from '../../stores/form.js';
    import { settings } from '../../stores/settings.js';
    import { validateChars } from '../helper/validate.js';
    import HelpButton from '../helpTextBox/HelpButton.svelte';
    import InputText from '../inputText/InputText.svelte';
    import Explanation from '../text/Explanation.svelte';
    import ExplanationDocument from '../text/ExplanationDocument.svelte';
    import InputTextUnknown from '../inputText/InputTextUnknown.svelte';
    import InputSelect from '../inputSelect/InputSelect.svelte';
    import InputSelectCountries from '../inputSelect/InputSelectCountries.svelte';
    import RadioButtonGroup from '../radio/RadioButtonGroup.svelte';
   
    export let store;
    export let labelClass = "daa-label-w400";

    let components = {};
    let inputHealthInsuranceType;
    let inputHealthInsuranceCompany;
    let inputHealthInsuranceCompany2;
    let inputHealthInsuranceCompanyName;
    let inputHasPensionContribution;
    
    $:showWarning = false;
    $:checkSilently = false;
    $:currentType = 0;
    $:privatePrivate = "-99999";

   
    const checkCurrentType = () => {

        currentType = 0;
        let testStr = store.healthInsuranceType;
        
        if (testStr.includes("privat")) { 
            currentType = 3;
        };
        if (testStr.includes("gesetzlich")) { 
            currentType = 1;
        };

        return (currentType > 0);
    }

    $:showMoreOptions = checkCurrentType();
    $:pensionContrShowMoreOptions = false;

    
    let pensionContributionOptions = [
        {id:"1", groupName:"PContribyesNo", label:"Ja",value:"yes"},
        {id:"0", groupName:"PContribyesNo", label:"Nein",value:"no"},
    ];
    

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const handleChangeInsuranceType = (index) =>{
        showMoreOptions = checkCurrentType();
    }

    const getStyleSecondSelect = (val) => {
        if( pensionContrShowMoreOptions ) return "";
        return "display:none;";
    }

    const handleSelectPrivat = (val) => {
        
        // store.inputHealthInsuranceCompany2
        inputHealthInsuranceCompany2.update();
        // store.healthInsuranceOperatingIdPrivate = 
    }

    const handlePensionContribution = (val) => {
        if(val === "yes") {
            pensionContrShowMoreOptions = true;
        } else {
            pensionContrShowMoreOptions = false; 
        }
        saveToStore();

    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    export function validate (boolSilent) {

        let ch1 = inputHealthInsuranceType.validate();
        let ch2 = "valid";
        let ch3 = "valid";

        if(inputHealthInsuranceCompany) {
            ch2 = inputHealthInsuranceCompany.validate();
        }
        if(inputHealthInsuranceCompany2 && store.hasPensionContribution === "yes") {
            ch3 = inputHealthInsuranceCompany2.validate();
        }
       
        if ( ch1 === "valid" && ch2 === "valid" && ch3 === "valid") return "valid";
        return "invalid";
    }

    export const getValue = () => {
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    export const update = () => {

       
        if(inputHealthInsuranceType) inputHealthInsuranceType.update();

        if(inputHealthInsuranceCompany) {
            inputHealthInsuranceCompany.update();
        }

        if(store.hasPensionContribution === "yes") {
           
            pensionContrShowMoreOptions = true;
            if(inputHasPensionContribution){
                inputHasPensionContribution.setValue(store.hasPensionContribution);
                
            }
        }
        if(store.hasPensionContribution === "no") {
           console.log("store.hasPensionContribution", "no")
           if(inputHasPensionContribution){
               inputHasPensionContribution.setValue(store.hasPensionContribution);
           }
           pensionContrShowMoreOptions = false;
       }
    };

    const saveToStore = () => {
        //console.log("saveToStore in Health, inputHasPensionContribution:",store)

        if(inputHealthInsuranceCompany2) {
            inputHealthInsuranceCompany2.update()
        };
       

    }
    
    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    onMount(async () => {
        update();
        if(inputHealthInsuranceCompany2) {
            inputHealthInsuranceCompany2.update()
        };
    });

    onDestroy(() => {
        saveToStore();
    });

    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    
</script>

<div >

    <InputSelect bind:this={inputHealthInsuranceType}  
            value = {store["healthInsuranceType"]}
            fieldName="healthInsuranceType"
            data={$fields["healthInsuranceType"]} 
            store = {store}
            labelClass="daa-input-grid-300"
            callback={handleChangeInsuranceType}>
    </InputSelect>

        
    {#if showMoreOptions}

        <div in:fly={{ y: -100, duration: 250 }} out:fade={{ duration: 20 }}>

            {#if currentType === 1 }
                <InputSelect bind:this={inputHealthInsuranceCompany}  
                    value = {store["healthInsuranceCompany"]}
                    fieldName="healthInsuranceCompany"
                    data={$fields["healthInsuranceCompany"]} 
                    store = {store}
                    labelClass="daa-input-grid-300">
                </InputSelect>
            {/if}

            {#if currentType === 3 }

                <Explanation 
                    fieldName="hasPensionContributionExplanation"
                    labelClass="daa-input-grid-300">
                </Explanation>

                <RadioButtonGroup bind:this={inputHasPensionContribution}
                    data={$fields["hasPensionContribution"]}  
                    fieldName="hasPensionContribution"
                    labelClass="daa-input-grid-300"
                    store = {store}
                    options = {pensionContributionOptions}
                    callback = {handlePensionContribution}>
                </RadioButtonGroup>

                {#if pensionContrShowMoreOptions}

                    <Explanation 
                        fieldName="hasPensionContributionExplanation2"
                        labelClass="daa-input-grid-300">
                    </Explanation>

                    
                {/if}
                <div style={ getStyleSecondSelect(pensionContrShowMoreOptions)}>
                <InputSelect bind:this={inputHealthInsuranceCompany2}  
                    value = {store["healthInsuranceCompanyPrivate"]}
                    fieldName="healthInsuranceCompanyPrivate"
                    data={$fields["healthInsuranceCompanyPrivate"]} 
                    store = {store}
                    labelClass="daa-input-grid-300"
                    >
                </InputSelect>
            </div>

                <ExplanationDocument 
                    fieldName="explainSendDocument"
                    labelClass="daa-input-grid-300">
                </ExplanationDocument>


            {/if}

        </div>

    {/if}
     
</div>


