import { writable } from 'svelte/store';

export const endpoints = writable({

    "EPpath":"",
    "EPlogin":"api/login",
    "EPchangePW": "api/user/password",
    "EPupload": "api/user/import",
    "EPUserList": "api/user/list",
    "EPUser": "api/user",
    "EPUserSave": "api/user/data",
    "EPAdmins": "api/admins",
    "EPGroups": "api/groups",
    "EPSearch": "api/user/search",
    "EPdownload": "api/user/export",
    "EPpage": "api/user/page",

    
});

// "EPpage1": "api/user/page1",

