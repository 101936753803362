<script> 

    export let callback;

    const handleClick = () => {
        callback();
    }
    
</script>


<div class="daa-backdrop" on:click={handleClick} role="none"></div>

