
<script>

    import { modal } from "../../stores/modal"; 

    export let positionFix = "false";

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const handleImpressum  = () => {
        $modal.current = "impressum";
    } 

    const handleDisclaimer  = () => {
        $modal.current = "disclaimer";
    } 


    const getStyle = (str) => {
        if (str === "true") return "position:fixed;";
        return "position:relative;";
    }
    
</script>

<div class="daa-footer" style={getStyle(positionFix)}>
    <div class="daa-footer-text">
        2024 DAA Stiftung  |  <span class="daa-link-impressum" on:click={handleImpressum}>Impressum</span> | <span class="daa-link-impressum" on:click={handleDisclaimer}>Datenschutz</span></div> 
</div>
