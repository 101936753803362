import { writable } from 'svelte/store';

export const users = writable([

]);

export const dummyUsers = writable([

    {"employeeID":"11223344", "firstName":"Der Vorname", "lastName":"Nachnamiger", "lastChange":"26.05.2024", "status":"1"},
    {"employeeID":"11223345", "firstName":"Anneliese", "lastName":"Unterberg", "lastChange":"26.05.2024", "status":"1"},
    {"employeeID":"11223346", "firstName":"Kurt", "lastName":"Klabauter", "lastChange":"26.05.2024", "status":"0"},
    {"employeeID":"11223347", "firstName":"Rüdiger", "lastName":"Rauhbein", "lastChange":"26.05.2024", "status":"1"},
    {"employeeID":"11223348", "firstName":"Hellmut", "lastName":"Hart-Hunzig", "lastChange":"26.05.2024", "status":"1"},

    {"employeeID":"66723344", "firstName":"Trude", "lastName":"Unruh", "lastChange":"26.05.2024", "status":"1"},
    {"employeeID":"66723345", "firstName":"Thorben", "lastName":"Tannenberg", "lastChange":"26.05.2024", "status":"1"},
    {"employeeID":"66723346", "firstName":"Bettina", "lastName":"Bautista", "lastChange":"26.05.2024", "status":"1"},
    {"employeeID":"66723347", "firstName":"Susi", "lastName":"Sorglos", "lastChange":"26.05.2024", "status":"0"},
    {"employeeID":"66723348", "firstName":"Stratiatella", "lastName":"DiMarzio", "lastChange":"26.05.2024", "status":"1"},

    {"employeeID":"88723344", "firstName":"Gina", "lastName":"Galante", "lastChange":"26.05.2024", "status":"1"},
    {"employeeID":"88723345", "firstName":"Ulf", "lastName":"Umbringer", "lastChange":"26.05.2024", "status":"0"},
    {"employeeID":"88723346", "firstName":"Rainer", "lastName":"Rüstig", "lastChange":"26.05.2024", "status":"1"},
    {"employeeID":"88723347", "firstName":"Babsi", "lastName":"Meier-Scholz", "lastChange":"26.05.2024", "status":"1"},
    {"employeeID":"88723348", "firstName":"Stefanie", "lastName":"Lehmann", "lastChange":"26.05.2024", "status":"0"},

]);
