<script>

    import { settings } from '../../stores/settings.js';
    import { modal } from "../../stores/modal";  
    import Icon from "../icon/Icon.svelte";
    

    $:showModalExport = false;
    $:showModalImport = false;

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

   
    const handleGoBackBtn = (str) => {
        $settings.view = "admin"
    }

    const handleCreateNewButton = () => {
        $modal.current = "createNewAdmin";
    }

</script>


<div class="daa-main-layout daa-admin-bar-full-width">

    <div></div>

    <div class="daa-adm-bar">

        <!-- left side -->
        <div class="daa-adm-bar-left">
            <div class="daa-adm-headline">Administration: Administrators</div>
           
           <div class="daa-admin-bar-no-btn-container">
                
            </div>
          
        </div>
       
        <!-- right side -->
        <div class="daa-adm-bar-right">

            <div style="margin-left:auto;"></div>

            <div style="margin-left:auto;"></div>

            <!-- new admin btn -->
            <div class="daa-admin-bar-btn-container" on:click={handleCreateNewButton} role="none">
                <div style="padding-top:6px;">
                    <Icon type="plus"></Icon>
                </div>
                <div>Neu</div>
            </div>


            <!-- back to admin button -->
            <div class="daa-admin-bar-btn-container" on:click={handleGoBackBtn} role="none">
                <div style="padding-top:8px;">
                    <Icon type="arrowRight"></Icon>
                </div>
                <div>Zurück</div>
            </div>
           
        </div>

    </div>

    <div></div>

 </div>
