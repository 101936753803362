<script> 

    // @ts-nocheck  
    import { onMount, onDestroy } from 'svelte'; 
    import { settings } from '../../stores/settings.js';
    import { endpoints } from '../../stores/endpoints.js';
    import ButtonCancel from "../button/ButtonCancel.svelte";
    import ButtonOk from "../button/ButtonOk.svelte";

    export let show = false;
    export let callback;

    let files;
    let override;

    $:buttonOkactive = files;
    $:showHintUnsuccess = false;
    $:showHintSuccess = false;

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const handleCancel = () => {
        handleClear();
        callback ("cancel");
    }

    const handleOk = () => {
        fetchUpload ();
    }

    const handleClear = () => {
        files = false;
    }

    const handleCloseDialog = () => {
       
        handleClear();
        if(showHintSuccess) {
            showHintSuccess = false;
            showHintUnsuccess = false;
            callback("success");
            return;
        }
        showHintSuccess = false;
        showHintUnsuccess = false;
        callback("cancel");
    }


    async function fetchUpload () {

        let overR = (override.checked === true) ? "true" : "false";

        const formData = new FormData();
        formData.append("file", files[0]);
        formData.append("updateExistingUser", overR);
        formData.append("groupsId", $settings.groupId);

        const response = await fetch($endpoints.EPpath+$endpoints.EPupload, {
            method:"POST",
            headers: {
                'Authorization': 'Bearer '+$settings.token
            },
            body:formData
        })

        const resp = await response;

        if (!resp.ok) {
            handleUnsuccess();
        } else {
            handleSuccess();
        }

 }

const handleUnsuccess = () => {  
    showHintUnsuccess = true;
}

const handleSuccess = () => {  
    showHintSuccess = true;
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////

onMount(() => {
    showHintSuccess = false;
    showHintUnsuccess = false;
});

onDestroy(() => {
    showHintSuccess = false;
    showHintUnsuccess = false;
});


</script>


{#if show}

<div class="daa-backdrop daa-backdrop-dark">

    <div class="daa-dialog-container">

        <div class="daa-modal-headline">Import (Excel)</div>

        <div style="height:20px;"></div>

        {#if !showHintSuccess}
             
        <div class="daa-dialog-import-pane">

            {#if !files}
                <label class="daa-upload-pane-headline" for="uploadFile"></label>
                <input accept=".xlsx, .csv" bind:files id="uploadFile" name="uploadFile" type="file" />
            {/if}

            {#if files}
                <div class="daa-selected-file-headline">Ausgewählte Datei:</div>
                {#each Array.from(files) as file}
                    <p>{file.name} ({file.size} bytes)</p>
                {/each}
                <button style="padding: 5px 20px;" on:click={handleClear}>Verwerfen</button>
            {/if}

        </div>

        <br>
        <input type="checkbox" bind:this={override} id="overrideExisting" name="overrideExisting" value="overrideExisting">
        <label for="overrideExisting">Vorhandene Honorarkräfte überschreiben ?</label><br>

        {#if showHintUnsuccess }
            <div class="daa-login-hint-invalid">Upload nicht erfolgreich.</div>
        {/if}

        <div class="daa-dialog-buttons-container">
            <ButtonCancel active={true} label="Abbrechen" callback={handleCancel}></ButtonCancel>
            <ButtonOk active={buttonOkactive} label="Upload" callback={handleOk}></ButtonOk>
        </div>
        
        {/if}

        {#if showHintSuccess}

            <div class="daa-dialog-import-pane">
                <div>Upload erfolgreich.</div>
            </div>

            <div class="daa-dialog-buttons-container">
                <ButtonOk active={true} label="Ok" callback={handleCloseDialog}></ButtonOk>
            </div>
        {/if}


    </div>


</div>

{/if}
