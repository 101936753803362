<script>

    // @ts-nocheck
    import { settings } from '../../stores/settings.js';
    import { endpoints } from '../../stores/endpoints.js';
    import { minChars } from '../helper/validate.js';
    import ButtonOk from '../button/ButtonOk.svelte';
    import Footer from '../footer/Footer.svelte';

    let oldPwd, inputPwd1, inputPwd2;
    $:btnActive = false;
    $:changeSuccess = false;
    $:showHintUnsuccess = false;

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const preCheck = (e) => {
        e.preventDefault();
        showHintUnsuccess = false;
        let checkOldPwd = ( minChars(oldPwd.value, 3));
        let checkPwd1 = ( minChars(inputPwd1.value, 8));
        let checkPwd2 = ( minChars(inputPwd2.value, 8));
        btnActive = (checkOldPwd && checkPwd1 && checkPwd2);
    }
     
    const handleOkButton = () => {
        if (btnActive) {
            fetchFinalPWD();
        }
    }

    
    async function fetchFinalPWD () {

        const response = await fetch($endpoints.EPpath+$endpoints.EPchangePW, {
                method:"PUT",
                headers: {
                    "Content-Type": "application/json",
                    'Accept':'application/json',
                    'Authorization': 'Bearer '+$settings.token
                },
                body: JSON.stringify({
                    "currentPassword": oldPwd.value,
                    "newPassword": inputPwd1.value,
                    "newPasswordConfirmation": inputPwd2.value
                })
            });

            const resp = await response.json();

            console.log ("change PWD response is", resp);

            if (resp.status === false) {
                console.log("Das ging daneben");
                handleUnsuccess();

            } else {

                if (resp.message === "Password changed successfully") {
                    changeSuccess = true;
                }
            }

    }

    const handleBackToLogin = () => {
        $settings.token = false,
        $settings.role = "none",
        $settings.view = "login";
    }

    const reset = () => {
        inputPwd1.value = "";
        inputPwd2.value = "";
        oldPwd.value = "";
        btnActive = false;
        changeSuccess = false;
    }

    const handleUnsuccess = () => {
        reset();
        showHintUnsuccess = true;
    }

</script>



<div class="daa-final-pw-explain">
    {#if !changeSuccess}
    <div class="daa-final-pw-explain-line1">Bevor es weiter geht, legen Sie bitte Ihr endgültiges Passwort fest.</div>
    <div class="daa-final-pw-explain-line2">Es muss mindestens 8 Zeichen lang sein. Es muss Groß-, Kleinbuchstaben, mindestens eine Zahl und ein Sonderzeichen enthalten.</div>
    {:else}
    <div class="daa-final-pw-explain-line1">.</div>
    <div class="daa-final-pw-explain-line2">.</div>

    {/if}
</div>


<div class="daa-card-login daa-card-login2">

    <div class="daa-card-login-head">Mein Passwort</div>

    <div class="daa-card-login-body">

        {#if !changeSuccess}
       
         <!-- old pwd -->
         <label class="daa-login-input-label" for="myoldPWD">Altes Passwort:</label>
         <input class="daa-login-input daa-login-mask-chars" type="text" id="myoldPWD" name="old" autocomplete="off"
         bind:this={oldPwd}
         on:input={preCheck}
         on:change={preCheck}> 
 
        <div style="height: 12px;"></div>

        <!-- pwd1 -->
        <label class="daa-login-input-label" for="myPWD1">Neues Passwort:</label>
        <input class="daa-login-input daa-login-mask-chars" type="text" id="myPWD1" name="new1" autocomplete="off"
        bind:this={inputPwd1}
        on:input={preCheck}
        on:change={preCheck}> 

        <div style="height: 12px;"></div>

        <!-- pwd2 -->
        <label class="daa-login-input-label" for="myPWD2">Neues Passwort erneut eingeben (kein Copy&Paste!):</label>
        <input class="daa-login-input daa-login-mask-chars" type="text" id="myPWD2" name="new2" autocomplete="off"
        bind:this={inputPwd2}
        on:input={preCheck}
        on:change={preCheck}> 

        {#if showHintUnsuccess }
            <div class="daa-login-hint-invalid">Eingaben ungültig.</div>
        {:else} 
            <div style="height:20px;"></div>
        {/if}


        <div class="daa-buttons-row">
            <ButtonOk label="Ok" active={btnActive} callback={handleOkButton}></ButtonOk>
        </div>

        {:else} 

        <div class="daa-hint-change-pwd-sucsess">
            <div>Ihr Passwort wurde erfolgreich geändert</div>
            <div>Jetzt bitte erneut einloggen.</div>
            <div style="height:30px;"></div>
            <ButtonOk label="Zum Login" active={changeSuccess} callback={handleBackToLogin}></ButtonOk>
        </div>

        {/if}

    </div>
  
    
</div>


<Footer positionFix="true"></Footer>
