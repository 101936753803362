<script>

    import { onMount, onDestroy } from 'svelte'; 
    import { fields } from '../../stores/form.js';
    import { settings } from '../../stores/settings.js';
    import { validateChars, noSpace } from '../helper/validate.js';
    import HelpButton from '../helpTextBox/HelpButton.svelte';

    export let fieldName = "none";
    export let value = "";
    export let store;
    export let labelClass = "daa-label-w400";
    export let checkBoxLabel = "Das weiß ich nicht";
    export let callbackInput = function(val){};
    export let callbackCheckbox = function(val){};
    
    let data = $fields[fieldName];
    let inputField;
    let checkB;

    $:isChecked = false;
    $:currentValue = value;
    $:tempCurrentValue = value;
    $:showWarning = false;
    $:checkSilently = false;
    $:checkBoxActive = true; // if false, box is dimmed


    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const handleInput = (e) => {
        if(currentValue !== "") isChecked = false;
        showWarning = false;
        $settings.showWarningInFooter = false;
        callbackInput(e.target.value);
        
    }

    const handleCheckbox = (e) => {
        isChecked = e.target.checked;
        if (isChecked) {
            tempCurrentValue = currentValue;
            currentValue = "";
        }
        if (!isChecked) {
            currentValue = tempCurrentValue;
        }
        showWarning = false;
        $settings.showWarningInFooter = false;
        callbackCheckbox(isChecked);
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    export function validate (boolSilent){

        checkSilently = !boolSilent;

        let rules = data.validate;
        if (!rules) return "valid";
        let checkChars = validateChars (currentValue, rules);
        if(checkChars !=="valid") {
            showWarning = true;
        }
        if(checkB.checked) {
            checkChars = "valid";
            showWarning = false;
        }
        return checkChars;
    } 

    export const getValue = () => {
        return currentValue;
    };

    export const getChecked = () => {
        return isChecked;
    };

    export const setValid = (bool) => {
        showWarning = !bool;
    };

    export const setCheckBoxActive = (bool) => {
        checkBoxActive = bool;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    export const update = () => {
        currentValue = store[fieldName];
        tempCurrentValue = store[fieldName];
        let deleteSpaces = noSpace(currentValue);
        if (currentValue === "unknown" || currentValue === "nicht bekannt" || deleteSpaces === "") {
            isChecked = true;
            currentValue = "";
            tempCurrentValue = "";
        }
        // saveToStore();
    }

    const saveToStore = () => {
        store[fieldName] = currentValue;
        if (isChecked) {
            store[fieldName] = "unknown";
        }
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    onMount(async () => {

    });

    onDestroy(() => {
        saveToStore();
    });

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    
</script>


<div class="daa-input-grid {labelClass}">

    <div class="daa-label-container">
        <label class="daa-input-label daa-fnt-std" for={fieldName}>{data.name}</label>
        {#if data.required} 
            <div class="daa-hint-asterix">*</div> 
        {/if}
    </div>
    
    <div class="daa-input-text-hint-container">

        <div class="daa-input-text-unknown-container">
            <input 
                class="daa-input-text daa-fnt-std" 
                style={(showWarning === false ) ? "": "border: 1px solid red;"}
                type="text" 
                id={fieldName} 
                name={fieldName} 
                bind:this={inputField}
                bind:value={currentValue}
                on:input={handleInput}> 

            {#if checkBoxActive}
            <input type="checkbox" bind:this={checkB} 
                bind:checked={isChecked} 
                on:change={handleCheckbox}
                id="{fieldName}_unknown" 
                name="{fieldName}_unknown" 
                value="{fieldName}_unknown">
                <label class="daa-checkbox-unknown-label" for="{fieldName}_unknown">{checkBoxLabel}</label>
            {/if}
            {#if !checkBoxActive}
            <input style="opacity:0.6;" type="checkbox" 
                id="{fieldName}_deactivated" 
                name="{fieldName}_deactivated" 
                value="{fieldName}_deactivated">
                <label class="daa-checkbox-unknown-label-deactivated " for="{fieldName}_deactivated">{checkBoxLabel}</label>
            {/if}
        </div>

        <HelpButton show={data.useHelp} ref={fieldName}></HelpButton>
       
    </div>
       
</div>


<!-- Error -->
{#if showWarning}

<div class="daa-input-grid {labelClass}">
    <div></div> 
    <div class="daa-warning">{data.error}</div>
</div>

{/if}

