<script>

    import { onMount, onDestroy } from 'svelte'; 
    import { settings } from '../../stores/settings.js';
    import { fields } from '../../stores/form.js';

    export let options = [];
    export let checked = "";
    export let data;
    export let fieldName;
    export let store;
    export let labelClass = "daa-label-w85";
    export let callback = function(val){};

    $:showHint = true;
    $:showWarning = false;
    $:groupValue = "";
    $:currentValue = "";
    let radios = {};

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const handleChange = (e) => {
        currentValue = e.target.value;
        saveToStore();
        showWarning = false;
        $settings.showWarningInFooter = false;
        callback(currentValue);
    }

    export const validate = () => {
        
        if (currentValue === "") {
            showWarning = true;
            return "invalid";
        }
        return "valid";
    }

    export const getValue = () => {
        return currentValue;
    }

    export const setValue = (val) => {
        currentValue = val;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    export const update = () => {
        currentValue = store[$fields[fieldName].linkTo];
    };

    export const saveToStore = () => {
        store[$fields[fieldName].linkTo] = currentValue;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    onMount(async () => {
        update();
    });

    onDestroy(() => {
        saveToStore();
    });

/////////////////////////////////////////////////////////////////////////////////////////////////////////


</script>


<div class="daa-input-grid daa-label-w20">

    <div class="daa-label-container"></div>
    
    <!-- head part -->
    <div class="daa-tree-explain" style="margin-top:10px;">
        {#if data.required} 
            <div class="daa-hint-asterix">*</div> 
        {/if}
        <div >{data.label}</div>
    </div>
       
</div>


<div class="daa-input-grid daa-label-w85">

   <div></div>
       
    <!-- radio group and error hint -->
    <div class="daa-radio-group-hint-container">


        <div class="daa-radio-group-tree" >

            {#each options as { id, groupName, label, value }, i}
            
            <div class="daa-radio-btn" >
                <input 
                    bind:this={radios[label]} 
                    type="radio" 
                    id={fieldName+id} 
                    name={groupName} 
                    value={value} 
                    checked = {value === currentValue}
                    on:change={handleChange}>
                <label for={fieldName+id}>{label}</label>
            </div> 

            {/each}
            
        </div>
        
        <!-- Error -->
        {#if showWarning}
            <div class="daa-warning" style="padding-left:5px;">{data.error}</div>
        {/if}

    </div>

</div>
