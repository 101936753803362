<script>

    export let type = "";
    export let isActive = false;
    export let isHover = false;
    

    const getViewBoxString = (w,h) => {
        return ['viewBox="0 0 ', w, ' ', h,'" ','width="', w, 'px"'].join("")
    }


    const getIconType = () => {

        if (type === "sendDoc") {
            iconNormal = sendDocNormal;
            iconActive = sendDocActive;
            iconHover  = sendDocActive;
        }

        if (type === "eye") {
            iconNormal = eyeNormal;
            iconActive = eyeActive;
            iconHover  = eyeActive;
        }
        if (type === "admins") {
            iconNormal = adminsNormal;
            iconActive = adminsActive;
            iconHover  = adminsActive;
        }
        if (type === "upload") {
            iconNormal = uploadNormal;
            iconActive = uploadActive;
            iconHover  = uploadActive;
        }
        if (type === "download") {
            iconNormal = downloadNormal;
            iconActive = downloadActive;
            iconHover  = downloadActive;
        }
        if (type === "arrowDown") {
            iconNormal = arrowDownNormal;
            iconActive = arrowDownActive;
            iconHover  = arrowDownActive;
        }
        if (type === "arrowRight") {
            iconNormal = arrowRightNormal;
            iconActive = arrowRightActive;
            iconHover  = arrowRightActive;
        }
        if (type === "checkbox") {
            iconNormal = checkBoxNormal;
            iconActive = checkBoxActive;
            iconHover  = checkBoxHover;
        }
        if (type === "radio") {
            iconNormal = radioNormal;
            iconActive = radioActive;
            iconHover  = radioHover;
        }
        if (type === "search") {
            iconNormal =  searchNormal;
            iconActive =  searchActive;
            iconHover  =  searchActive;
        }
        if (type === "trash") {
            iconNormal =  trashNormal;
            iconActive =  trashActive;
            iconHover  =  trashActive;
        }
        if (type === "ok") {
            iconNormal =  okNormal;
            iconActive =  okActive;
            iconHover  =  okActive;
        }
        if (type === "minus") {
            iconNormal =  minusNormal;
            iconActive =  minusActive;
            iconHover  =  minusActive;
        }
        if (type === "plus") {
            iconNormal =  plusNormal;
            iconActive =  plusActive;
            iconHover  =  plusActive;
        }

        if (type === "edit") {
            iconNormal =  editNormal;
            iconActive =  editActive;
            iconHover  =  editActive;
        }
       
    }
    
    let open        = `<svg xmlns="http://www.w3.org/2000/svg"`; 
    let closeOpen   = `><path `;

    let fill_1   = `class="daa-icon-fill"`;
    let fill_2   = `class="daa-icon-hover"`;
    let fill_3   = `class="daa-icon-active"`;
   
    let adminsPath    = `d="M8.2,13.8c0,1.5-1.2,2.7-2.7,2.7s-2.7-1.2-2.7-2.7s1.2-2.7,2.7-2.7S8.2,12.3,8.2,13.8z M12.7,5.5
	c0-1.5-1.2-2.7-2.7-2.7S7.3,4,7.3,5.5S8.5,8.3,10,8.3S12.7,7,12.7,5.5z M14.6,11.1c-1.5,0-2.7,1.2-2.7,2.7s1.2,2.7,2.7,2.7
	s2.7-1.2,2.7-2.7S16.1,11.1,14.6,11.1z"`;
    
    let eyePath    = `d="M18.6,11.2C16.8,3.4,3,3.5,1.3,11.4c1.7,8.2,15.7,8.2,17.3,0L18.6,11.2z M10,16.1c-3.3,0-6.2-1.9-7-4.6
	c0.7-2.2,2.8-3.9,5.5-4.3c-0.6,0.2-1.1,0.6-1.4,1c0.8-0.2,1.6,0.5,1.5,1.2c0,1.3-1.7,1.8-2.4,0.7c-0.3,2.2,1.5,4.1,3.7,4.1
	C14,14.3,15,8.3,11.1,7c2.8,0.4,5.2,2.1,5.9,4.4C16.2,14.1,13.3,16.1,10,16.1z"`;
    
   

    let sendDocPath   = `d=" M14.7,0H0v28h21V6.3L14.7,0z M14.5,1.3l5.2,5.2h-5.2V1.3z M1,27V1h12.5v6.5H20V27H1z"`;
    let uploadPath    = `d="M8.5,7.7h-2L10,3.3l3.5,4.3h-2V12h-3V7.7z M15.5,11.8c-0.4,0-0.8,0.3-0.8,0.8v2.2H5.2v-2.2c0-0.4-0.3-0.8-0.8-0.8s-0.8,0.3-0.8,0.8v3c0,0.4,0.3,0.8,0.8,0.8h11c0.4,0,0.8-0.3,0.8-0.8v-3C16.2,12.1,15.9,11.8,15.5,11.8z"`;
    let downloadPath  = `d="M10,12L6.5,7.7h2V3.3h3v4.3h2L10,12z M15.5,11.8c-0.4,0-0.8,0.3-0.8,0.8v2.2H5.2v-2.2c0-0.4-0.3-0.8-0.8-0.8s-0.8,0.3-0.8,0.8v3c0,0.4,0.3,0.8,0.8,0.8h11c0.4,0,0.8-0.3,0.8-0.8v-3C16.2,12.1,15.9,11.8,15.5,11.8z"`;
    
    let arrowLeftPath    = `d="M8.8,11.3c0,0,4.5-4.5,4.5-4.5c0.9-0.9,2.3,0.5,1.4,1.4L10.9,12c0,0,3.8,3.8,3.8,3.8c0.9,0.9-0.5,2.3-1.4,1.4l-4.5-4.5c-0.2-0.2-0.3-0.5-0.3-0.7S8.6,11.5,8.8,11.3z"`;
    let arrowRightPath   = `d="M15.5,12c0,0.3-0.1,0.5-0.3,0.7l-4.5,4.5c-0.9,0.9-2.3-0.5-1.4-1.4c0,0,3.8-3.8,3.8-3.8L9.3,8.2c-0.9-0.9,0.5-2.3,1.4-1.4c0,0,4.5,4.5,4.5,4.5C15.4,11.5,15.5,11.7,15.5,12z"`;
    let arrowDownPath   = `d="M12,15.5c-0.3,0-0.5-0.1-0.7-0.3l-4.5-4.5c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l3.8,3.8l3.8-3.8c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4l-4.5,4.5C12.5,15.4,12.3,15.5,12,15.5z"`;
    let checkBoxChecked = `d="M17,0H3C1.4,0,0,1.3,0,3v14c0,1.6,1.3,3,3,3h14c1.7,0,3-1.4,3-3V3C20,1.3,18.7,0,17,0z M8.5,17l-5.7-4.7l1.7-2.6l3.7,3.3l6.5-9.8l2.2,1.7L8.5,17z"`;
    let checkBoxEmpty   = `d="M17,1.5c0.8,0,1.5,0.7,1.5,1.5v14c0,0.8-0.7,1.5-1.5,1.5H3c-0.8,0-1.5-0.7-1.5-1.5V3c0-0.8,0.7-1.5,1.5-1.5H17M17,0H3C1.4,0,0,1.3,0,3v14c0,1.6,1.3,3,3,3h14c1.6,0,3-1.4,3-3V3C20,1.3,18.7,0,17,0L17,0z"`;
    let boxInner        = `fill="rgb(255,255,255)" d="M17,1.1c0.8,0,1.9,1.1,1.9,1.9v14c0,0.8-1.1,1.9-1.9,1.9H3c-0.8,0-1.9-1.1-1.9-1.9V3c0-0.8,1.1-1.9,1.9-1.9H17"/`;
    let radioEmpty      = `d="M10,1.4c4.7,0,8.6,3.9,8.6,8.6s-3.9,8.6-8.6,8.6S1.4,14.7,1.4,10S5.3,1.4,10,1.4M10,0C4.5,0,0,4.5,0,10s4.5,10,10,10s10-4.5,10-10S15.5,0,10,0L10,0z"`;
    let radioChecked    = `d="M10,0C4.5,0,0,4.5,0,10c0,5.5,4.5,10,10,10s10-4.5,10-10C20,4.5,15.5,0,10,0zM10,14c-2.2,0-4-1.8-4-4c0-2.2,1.8-4,4-4s4,1.8,4,4C14,12.2,12.2,14,10,14z"`;
    let radioInner      = `fill="rgb(255,255,255)" d="M15,15H5V5h10V15z"/`;
    let search          = `d="M19 17l-5.15-5.15a7 7 0 1 0-2 2L17 19zM3.5 8A4.5 4.5 0 1 1 8 12.5 4.5 4.5 0 0 1 3.5 8z"`;
    let trashPath       = `d="M17,4.2C17,4.6,16.6,5,16.1,5H3.9C3.4,5,3,4.6,3,4.2c0-0.5,0.4-0.8,0.9-0.8H7c0-0.5,0.4-0.8,1-0.8h4c0.5,0,1,0.3,1.1,0.8h3C16.6,3.3,17,3.7,17,4.2z M4.9,16.2c0,0.8,0.6,1.3,1.4,1.3h7.5c0.8,0,1.4-0.6,1.4-1.3l0.9-9.5H3.9L4.9,16.2z"`;
    let okPath          = `d="M15.8,5.1c0,0-6.1,10.5-6.1,10.5c-0.3,0.5-0.9,0.6-1.4,0.4v0c-0.1-0.1-0.2-0.1-0.2-0.2c0,0-3.6-3.8-3.6-3.8c-0.9-0.9,0.6-2.3,1.4-1.4l2.7,2.8l5.4-9.4c0.3-0.5,0.9-0.6,1.4-0.4C15.9,4,16.1,4.6,15.8,5.1z"`;
    let minusPath       = `d= "M16,11.2H4c-0.7,0-1.2-0.6-1.2-1.2S3.3,8.8,4,8.8h12c0.7,0,1.2,0.6,1.2,1.2S16.7,11.2,16,11.2z"`;
    let plusPath        = `d="M16.5,11H11v5.5H9V11H3.5V9H9V3.5h2V9h5.5V11z"`;
    let editPath        = `d="M14.5,12.8l1.5-1.5v5.2c0,0.8-0.7,1.5-1.5,1.5h-9C4.7,18,4,17.3,4,16.5v-12C4,3.7,4.7,3,5.5,3h9C15.3,3,16,3.7,16,4.5V5
	l-1.5,1.5v-2h-9v12h9V12.8z M13.3,12.6l5.5-5.5l-1.7-1.7l-5.5,5.5L13.3,12.6z M10.1,14l2.7-1l-1.7-1.7L10.1,14z"`;
 
 
    let close       = `/></svg>`;

    let sendDocNormal  = [open, getViewBoxString(21,28), closeOpen, fill_1, sendDocPath, close].join("");
    let sendDocActive  = [open, getViewBoxString(21,28), closeOpen, fill_1, sendDocPath, close].join("");

    let adminsNormal = [open, getViewBoxString(20,20), closeOpen, fill_1, adminsPath, close].join("");
    let adminsActive = [open, getViewBoxString(20,20), closeOpen, fill_2, adminsPath, close].join("");
    let uploadNormal = [open, getViewBoxString(20,20), closeOpen, fill_1, uploadPath, close].join("");
    let uploadActive = [open, getViewBoxString(20,20), closeOpen, fill_2, uploadPath, close].join("");
    let downloadNormal = [open, getViewBoxString(20,20), closeOpen, fill_1, downloadPath, close].join("");
    let downloadActive = [open, getViewBoxString(20,20), closeOpen, fill_2, downloadPath, close].join("");
    let arrowDownNormal = [open, getViewBoxString(24,24), closeOpen, fill_1, arrowDownPath, close].join("");
    let arrowDownActive = [open, getViewBoxString(24,24), closeOpen, fill_2, arrowDownPath, close].join("");
    let arrowLeftNormal = [open, getViewBoxString(24,24), closeOpen, fill_1, arrowLeftPath, close].join("");
    let arrowLeftActive = [open, getViewBoxString(24,24), closeOpen, fill_2, arrowLeftPath, close].join("");
    let arrowRightNormal = [open, getViewBoxString(24,24), closeOpen, fill_1, arrowRightPath, close].join("");
    let arrowRightActive = [open, getViewBoxString(24,24), closeOpen, fill_2, arrowRightPath, close].join("");
    let checkBoxNormal = [open, getViewBoxString(20,20), closeOpen, boxInner, closeOpen, fill_1, checkBoxEmpty, close].join("");
    let checkBoxActive = [open, getViewBoxString(20,20), closeOpen, boxInner, closeOpen, fill_2, checkBoxChecked, close].join("");
    let checkBoxHover  = [open, getViewBoxString(20,20), closeOpen, boxInner, closeOpen, fill_2, checkBoxEmpty, close].join("");
    let radioNormal = [open, getViewBoxString(20,20), closeOpen, radioInner, closeOpen, fill_1, radioEmpty, close].join("");
    let radioActive = [open, getViewBoxString(20,20), closeOpen, radioInner, closeOpen, fill_2, radioChecked, close].join("");
    let radioHover  = [open, getViewBoxString(20,20), closeOpen, radioInner, closeOpen, fill_2, radioEmpty, close].join("");
   
  
    let searchNormal = [open, getViewBoxString(20,20), closeOpen, fill_1, search, close].join("");
    let searchActive = [open, getViewBoxString(20,20), closeOpen, fill_2, search, close].join("");

    let trashNormal = [open, getViewBoxString(20,20), closeOpen, fill_1, trashPath, close].join("");
    let trashActive = [open, getViewBoxString(20,20), closeOpen, fill_2, trashPath, close].join("");

    let eyeNormal = [open, getViewBoxString(20,20), closeOpen, fill_1, eyePath, close].join("");
    let eyeActive = [open, getViewBoxString(20,20), closeOpen, fill_2, eyePath, close].join("");

    let okNormal = [open, getViewBoxString(20,20), closeOpen, fill_1, okPath, close].join("");
    let okActive = [open, getViewBoxString(20,20), closeOpen, fill_2, okPath, close].join("");

    let minusNormal = [open, getViewBoxString(20,20), closeOpen, fill_1, minusPath, close].join("");
    let minusActive = [open, getViewBoxString(20,20), closeOpen, fill_2, minusPath, close].join("");

    let plusNormal = [open, getViewBoxString(20,20), closeOpen, fill_1, plusPath, close].join("");
    let plusActive = [open, getViewBoxString(20,20), closeOpen, fill_2, plusPath, close].join("");

    let editNormal = [open, getViewBoxString(20,20), closeOpen, fill_1, editPath, close].join("");
    let editActive = [open, getViewBoxString(20,20), closeOpen, fill_2, editPath, close].join("");


    let iconActive;
    let iconNormal;
    let iconHover;
    
    getIconType();
    
    $: current = 
        (isActive) 
        ? iconActive 
        : (isHover) ? iconHover : iconNormal;


</script>


 
<div >
    {@html current}
</div>
