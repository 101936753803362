<script>

    import { settings } from '../../stores/settings.js';
    import Icon from "../icon/Icon.svelte";
    
    export let callback;

    $:showModalExport = false;
    $:showModalImport = false;

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

   
    const handleGoBackBtn = (str) => {
        $settings.view = "admin"
    }

    const handleImportButton = () => {
        showModalImport = true;

    }


</script>


<div class="daa-main-layout daa-admin-bar-full-width">

    <div></div>

    <div class="daa-adm-bar">

        <!-- left side -->
        <div class="daa-adm-bar-left">
            <div class="daa-adm-headline">Administration: User</div>
           
           <div class="daa-admin-bar-no-btn-container">
                <div class="daa-adm-bar-company-no"></div>
                <div class="daa-adm-bar-company-name"></div>
            </div>
          
        </div>
       
        <!-- right side -->
        <div class="daa-adm-bar-right">

            <div style="margin-left:auto;"></div>

            <!-- back to admin button -->
            <div class="daa-admin-bar-btn-container" on:click={handleGoBackBtn} role="none">
                <div style="padding-top:8px;">
                    <Icon type="arrowRight"></Icon>
                </div>
                <div>Zurück</div>
            </div>
           
        </div>

    </div>

    <div></div>

 </div>
