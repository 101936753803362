<script> 

    // @ts-nocheck

    import { onMount, onDestroy } from 'svelte';  
    import { modal } from "../../stores/modal"; 
    import { options } from "../../stores/options.js";  
    import { fields } from "../../stores/form.js"; 
    import { settings } from '../../stores/settings';
    import { endpoints } from '../../stores/endpoints'; 
    import { tempAdmin, tempCreateAdmin, emptyAdmin } from "../../stores/admin";  
    import { newAdminObj, newEmptyAdminObj } from '../../stores/newAdminObj';
    import { groups } from '../../stores/groups';   
    import InputText from "../inputText/InputText.svelte";
    import InputSelect from "../inputSelect/InputSelect.svelte";
    import InputEmail from "../inputText/InputEmail.svelte";
    import ButtonCancel from "../button/ButtonCancel.svelte";
    import ButtonOk from "../button/ButtonOk.svelte";

    export let data;
    export let callback;

    let components = {};

    $:showHintUnsuccess = false;
    $:showHintSuccess = false;
    $:isValid = true;

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const prepare = () => {
        // $tempAdmin = {...emptyAdmin};
        newAdminObj.adminData = {...newEmptyAdminObj.adminData};
    }

    const handleCancel = () => {
        newAdminObj.adminData = {...newEmptyAdminObj.adminData};
        showHintUnsuccess = false;
        $modal.current = "none";
    }

    const handleLeave = () => {
        // $tempAdmin = {};
        newAdminObj.adminData = {...newEmptyAdminObj.adminData};
        showHintSuccess = false;
        showHintUnsuccess = false;
        $modal.current = "none";
    }

    const validateComponents = () => {
        isValid = true;
        Object.entries(components).forEach(([key, component]) => {   
           let result = component.validate();
           console.log("validate component result is", result);
           if (result !== "valid") isValid = false;
        }) 
    }

    const handleOk = () => {
        components["adminCompany"].update();
        components["adminEMail"].update();
        validateComponents ();
        if (isValid) createAdmin();
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    async function createAdmin () {

        $tempCreateAdmin.id = newAdminObj.adminData.groupsId;
        $tempCreateAdmin.firstName = newAdminObj.adminData.adminFirstName;
        $tempCreateAdmin.lastName = newAdminObj.adminData.adminLastName;
        $tempCreateAdmin.username = newAdminObj.adminData.adminUserName;
        $tempCreateAdmin.password = newAdminObj.adminData.adminInitialPWD;
        $tempCreateAdmin.eMail = newAdminObj.adminData.adminEMail;
        $tempCreateAdmin.groupsId = newAdminObj.adminData.groupsId;
        console.log("new admin data before save: ", JSON.stringify($tempCreateAdmin))
       
        const response = await fetch($endpoints.EPpath+$endpoints.EPAdmins, {
            method:"POST",
            headers: {
                "Content-Type": "application/json",
                'Accept':'application/json',
                'Authorization': 'Bearer '+$settings.token
            },
            body: JSON.stringify($tempCreateAdmin)
            // body: JSON.stringify(JSON.stringify(extractData))
        })

    const resp = await response;

            if (!resp.ok) {
                handleUnsuccess();
            } else {
                handleSuccess();
            }
    }

    const handleUnsuccess = () => {  
        showHintUnsuccess = true;
    }

    const handleSuccess = () => {  
        showHintSuccess = true;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    onMount(async () => {
	     prepare();
	});

    const saveToStore = () => {
        // store[fieldName] = currentValue;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const getValues = () => {
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

</script>


{#if $modal.current === "createNewAdmin"}

<div class="daa-backdrop daa-backdrop-dark">

    <div class="daa-dialog-container">

        <div class="daa-modal-headline">Neu(e) Administrator(in)</div>

        <div style="height:30px;"></div>

        <!-- normal dialog -->
        {#if !showHintUnsuccess && !showHintSuccess}
       
            <InputText bind:this={components["adminFirstName"]} 
                fieldName="adminFirstName" 
                store={newAdminObj.adminData} 
                labelClass="daa-label-w140" >
            </InputText>
            <InputText bind:this={components["adminLastName"]} 
                fieldName="adminLastName" 
                store={newAdminObj.adminData} 
                labelClass="daa-label-w140" >
            </InputText>
            <InputText bind:this={components["adminUserName"]} 
                fieldName="adminUserName" 
                store={newAdminObj.adminData} 
                labelClass="daa-label-w140" >
            </InputText>
            <InputText bind:this={components["adminInitialPWD"]} 
                fieldName="adminInitialPWD" 
                store={newAdminObj.adminData} 
                labelClass="daa-label-w140" >
            </InputText>
            <InputEmail bind:this={components["adminEMail"]} 
                fieldName="adminEMail" 
                store={newAdminObj.adminData} 
                labelClass="daa-label-w140">
            </InputEmail>
            <InputSelect bind:this={components["adminCompany"]} 
                fieldName="adminCompany" 
                store={newAdminObj.adminData} 
                data={$fields["adminCompany"]} 
                labelClass="daa-label-w140">
            </InputSelect>

            <div class="daa-dialog-buttons-container">
                <ButtonCancel active={true} label="Abbrechen" callback={handleCancel}></ButtonCancel>
                <ButtonOk active={true} label="Ok" callback={handleOk}></ButtonOk>
            </div>

        {/if}

         <!-- failed -->
         {#if showHintUnsuccess }

            <div class="daa-login-hint-invalid">Vorgang nicht erfolgreich.</div>
            <div class="daa-dialog-buttons-container">
                <ButtonCancel active={true} label="Abbrechen" callback={handleLeave}></ButtonCancel>
            </div>
         {/if}

         <!-- successful -->
         {#if showHintSuccess }

            <div class="daa-login-hint-success">Neue(r) Admin wurde angelegt.</div>
            <div class="daa-dialog-buttons-container">
                <ButtonOk active={true} label="Ok" callback={handleLeave}></ButtonOk>
            </div>
         {/if}

    </div>

</div>

{/if}
