<script>

    import { settings } from '../../stores/settings.js';
    import { groups } from '../../stores/groups.js';
    import Icon from "../icon/Icon.svelte";
    import ModalExport from '../modal/ModalExport.svelte';
    import ModalImport from '../modal/ModalImport.svelte';
    import ModalSwitchCompany from '../modal/ModalSwitchCompany.svelte';

    export let callback;
    export let callbackSearch

    $:groupFound = $groups.find((element) => element.id === $settings.groupId);
    $:showModalExport = false;
    $:showModalImport = false;
    $:showModalSwitchCompany = false;

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const handleSearchInput = (e) => {
        let searchFor = e.target.value;
        callbackSearch(searchFor);
    }

    const handleExportButton = () => {
        showModalExport = true;
    }

    const handleModalExport = (str) => {
        showModalExport = false;
    }

    const handleImportButton = () => {
        showModalImport = true;
    }

    const handleModalImport = (str) => {
        showModalImport = false;
        if (str === "success") {
            callback("uploadSuccess");
        }
    }

    const handleAdmins = () => {
        $settings.view = "adminslist";
    }

    const handleModalSwitchCompanyButton = () => {
        showModalSwitchCompany = true;
    }

    const handleModalSwitchCompany = (str) => {

        if (str == "ok") {
            console.log("switched company")
            $settings.groupId=settings.groupsId;
            showModalSwitchCompany = false;
            callback("uploadSuccess");
        }
        if (str == "cancel") {
                 console.log("canceld")
                 showModalSwitchCompany = false;
                 callback("uploadSuccess");
        }
        console.log(str)
    }

</script>


<div class="daa-main-layout daa-admin-bar-full-width">

    <div></div>

    <div class="daa-adm-bar">

        <!-- left side -->
        <div class="daa-adm-bar-left">
            <div class="daa-adm-headline">Administration</div>
            {#if $settings.role ==="superAdmin"}
            <div class="daa-admin-bar-btn-container" on:click={handleModalSwitchCompanyButton} role="none">
                <div class="daa-adm-bar-company-no">{groupFound.id}</div>
                <div class="daa-adm-bar-company-name">{groupFound.entityName}</div>
                <div style="padding-top:10px;">
                    <Icon type="arrowDown"></Icon>
                </div>
            </div>
            {:else}
            <div class="daa-admin-bar-no-btn-container">
                <div class="daa-adm-bar-company-no">{groupFound.id}</div>
                <div class="daa-adm-bar-company-name">{groupFound.entityName}</div>
            </div>
            {/if}
        </div>
       

        <!-- right side -->
        <div class="daa-adm-bar-right">

            <div class="daa-input-search-container">
                <div style="padding-top:4px;">
                    <Icon type="search"></Icon>
                </div>
                <input 
                class="daa-input-search daa-fnt-std" 
                type="text" 
                id="searchUser" 
                name="searchUser"  
                on:input={handleSearchInput}> 
            </div>

            <div style="margin-left:auto;"></div>

            <!-- upload btn -->
            <div class="daa-admin-bar-btn-container" on:click={handleExportButton} role="none">
                <div style="padding-top:6px;">
                    <Icon type="upload"></Icon>
                </div>
                <div>Export</div>
            </div>

            <!-- download btn -->
            <div class="daa-admin-bar-btn-container" on:click={handleImportButton} role="none">
                <div style="padding-top:6px;">
                    <Icon type="download"></Icon>
                </div>
                <div>Import</div>
            </div>

            {#if $settings.role ==="superAdmin"}
            <!-- admins btn -->
            <div class="daa-admin-bar-btn-container" on:click={handleAdmins} role="none">
                <div style="padding-top:6px;">
                    <Icon type="admins"></Icon>
                </div>
                <div class="daa-hide-label-text">Admins</div>
            </div>
            {/if}
           
        </div>

    </div>

    <div></div>

 </div>

 <ModalExport show={showModalExport} callback={handleModalExport}></ModalExport>
 <ModalImport show={showModalImport} callback={handleModalImport}></ModalImport>
 <ModalSwitchCompany show={showModalSwitchCompany} callback={handleModalSwitchCompany}></ModalSwitchCompany>
