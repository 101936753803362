
<script>

    import { onMount } from 'svelte';  
    import { settings } from '../../stores/settings.js'; 
   
    export let callback;

    let rangeWidth = 5;
    $:currentPage = 1;
    $:rangeShift = 0;
    let range = [];
    
   
    /////////////////////////////////////////////////////////////////////////////////////////////////////////


    const createRange = (pag) => {
        
        let from = rangeShift*rangeWidth+1;
        let to = from + rangeWidth;
        let total = $settings.pagination.last_page;
        let arr = [];
        for( let i = from; i < to; i++) {
            if(i <= total) {
                arr.push(i);
            }
        }
        // console.log("range:", range);
        return arr;
    }
    
    const handleClick = (e) => { 
        currentPage = parseInt(e.target.innerText);
        callback(currentPage);
    }

    const handleClickLeft = () => {
        if ( rangeShift > 0) {
            rangeShift--;
        }
        currentPage = parseInt((rangeShift)*rangeWidth);
        callback(currentPage);
    }

    const handleClickRight = () => {
        if ( (rangeShift+1)*rangeWidth < $settings.pagination.last_page) {
            rangeShift++;
            currentPage = parseInt((rangeShift+1)*rangeWidth);
            callback(currentPage);
        }
    }

    const getStyle = (val, currentPage) => {
        if (val === currentPage) return "daa-table-pagination-box-current";
        return "";
    }

    onMount(async () => {
	});


    /////////////////////////////////////////////////////////////////////////////////////////////////////////

</script>


<div class="daa-table-pagination-container">

    <div class="daa-table-pagination-items">

        <div class=" daa-table-pagination-box-direction"
            on:click={handleClickLeft}
            role="none">
            &#60;
        </div> 
    
        <div class="daa-table-pagination-page-items ">
            {#each createRange($settings.pagination) as item}
                <div class="daa-table-pagination-box {getStyle(item, currentPage)}" 
                on:click={handleClick}
                role="none">
                {item}
                </div>
            {/each}
        </div>
    
        <div class="daa-table-pagination-box-direction"
            on:click={handleClickRight}
            role="none">
            &#62;
        </div> 
    
    </div>

</div>

