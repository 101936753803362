<script>

    import Icon from "./Icon.svelte";

    export let type = "settings";
    export let callback;
    export let id = "-1";

    $: isMouseOver = false;
    $: isActive = false;
   

    const handleMouseEnter = () => {
        isMouseOver = true;
    }

    const handleMouseLeave = () => {
        isMouseOver = false;
    }

    const handleClick = () => {
        callback(id);
    }

    const handleKey = () => {
    }

</script>


<div class="daa-icon-btn" 
    on:mouseenter={handleMouseEnter}
    on:mouseleave={handleMouseLeave}
    on:click={handleClick}
    on:keyup={handleKey}
    role="none">
    
    <Icon type={type} isActive={isActive} isHover={isMouseOver}></Icon>
</div>