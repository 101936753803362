<script>

    import { onMount, onDestroy } from 'svelte'; 
    import { fields } from '../../stores/form.js';
    import { settings } from '../../stores/settings.js';
    import { filterStringToInt } from '../helper/validate.js';
    import HelpButton from '../helpTextBox/HelpButton.svelte';

    export let fieldName = "none";
    export let value = "";
    export let store;
    export let labelClass = "daa-label-w400";
    export let callback = function (val){};
   
    let data = $fields[fieldName];
    let inputField;
    $:showWarning = false;
    $:checkSilently = false;
    $:currentValue = value || 0;


    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const handleInput = (e) => {
        currentValue = filterStringToInt(e.target.value).toString();
        store[fieldName] = currentValue;
        callback(currentValue);
        showWarning = false;
        $settings.showWarningInFooter = false;
    }

    const handleChange = (e) => {
        // let isValid = check(e);
        // callback(isValid);
    }

    const check = (e) => {
    }

    // const check = (e) => {
    //     let rules = data.validate;
    //     if (!rules) return "valid";
    //     let checkChars = validateChars (inputField.value, rules);
    //     if(checkChars !=="valid") showWarning = true;
    //     return checkChars;
    // }

   /////////////////////////////////////////////////////////////////////////////////////////////////////////

    export function validate (boolSilent) {

        checkSilently = !boolSilent;
        console.log("validate in number", currentValue);
    }


    export const getValue = () => {
        return currentValue;
    };

    export const setValue = (val) => {
        currentValue = val;
    };

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    export const update = () => {
        currentValue = store[fieldName];
    };

    const saveToStore = () => {
        store[fieldName] = currentValue;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    onMount(async () => {
        
    });

    onDestroy(() => {
        saveToStore();
    });

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

</script>


<div class="daa-input-grid {labelClass}">

    <div class="daa-label-container">
        <label class="daa-input-label daa-fnt-std" for={fieldName}>{data.name}</label>
        {#if data.required} 
            <div class="daa-hint-asterix">*</div> 
        {/if}
    </div>
    
    <div class="daa-input-text-hint-container">
        <input 
            class="daa-input-text daa-fnt-std" 
            style="width:60px; text-align:center; {(showWarning === false ) ? "": "border: 1px solid red;"}"
            type="text" 
            id={fieldName} 
            name={fieldName} 
            bind:this={inputField}
            bind:value={currentValue}
            on:input={handleInput}
            on:change={handleInput}
            maxlength="2"> 

        <HelpButton show={data.useHelp} ref={fieldName}></HelpButton>
       
    </div>
       
</div>


<!-- Error -->
{#if showWarning}

<div class="daa-input-grid {labelClass}">
    <div></div> 
    <div class="daa-warning">{data.error}</div>
</div>

{/if}

