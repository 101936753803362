<script>

    // @ts-nocheck
    import { onMount } from 'svelte';  
    import { settings } from '../../stores/settings.js';
    import { users } from '../../stores/users.js';
    import { user } from '../../stores/user.js';
    import { currentUserId } from '../../stores/user.js';
    import { groups } from '../../stores/groups.js';
    import { endpoints } from '../../stores/endpoints.js';
    import { options } from '../../stores/options.js';
    import { modal } from "../../stores/modal";  
    import AdminBar from './AdminBar.svelte';
    import Table from '../table/Table.svelte';
    import TableRow from '../table/TableRow.svelte';
    import TableUsers from '../table/TableUsers.svelte';
    import Footer from '../footer/Footer.svelte';
    import ModalHint from '../modal/ModalHint.svelte';
    import ModalDelete from '../modal/ModalDelete.svelte';
    import ModalDeleteUser from '../modal/ModalDeleteUser.svelte';
    import ModalDeleteAdmin from '../modal/ModalDeleteAdmin.svelte';
    import ModalEditAdmin from '../modal/ModalEditAdmin.svelte';
    import ModalImpressum from '../modal/ModalImpressum.svelte';

    $:currentCell = $settings.userSortBy;
    $:showHintUnsuccess = false;
    $:showHintSuccess = false;
    $:searchForString ="";

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const getHeadCellClass = (str, curr) => {
        if (curr === str) {
            return "daa-table-head-cell-sortable-active";
        }
        return "daa-table-head-cell-sortable";
    }

    const handleClickHeadCell = (e) => {
        if(e.target.id === $settings.userSortBy) {
            $settings.userSortDirection === "asc" ? $settings.userSortDirection = "desc" : $settings.userSortDirection = "asc";
        }
        else {
            $settings.userSortDirection = "asc";
            $settings.userSortBy = e.target.id;
        }
        currentCell = e.target.id;
        search();
    }

    const handleModalDelete = (str) => {
        $modal.current = "none";
        console.log("about to delete")
    }

    const handleModalDeleteUser= (str) => {
        $modal.current = "none";
        if(str === "success") {
            $currentUserId.id = -99999;
            $currentUserId.employeeId = -99999;
            loadUsers (1);
        }
    }

    const handleModalEdit = (str) => {
        $modal.current = "none";
        console.log("about to edit")
    }

    const handleAdminBarLoadUsers = (str) => {
        if (str === "uploadSuccess") {
            loadUsers (1);
        }
    }


    ////////////////////////////////// search  //////////////////////////////////////////////////////////////

    const handleSearch = (str) => {
        searchForString = str;
        search();
    }

    async function search () {

        let paramSearchFor = "?search="+searchForString;
        let paramSortBy = "&sortby="+$settings.userSortBy;
        let paramDirection = "&sortdirection="+$settings.userSortDirection;
        let combi = paramSearchFor+paramSortBy+paramDirection;

        const response = await fetch($endpoints.EPSearch+combi, {
            method:"GET",
            headers: {
                "Content-Type": "application/json",
                'Accept':'application/json',
                'Authorization': 'Bearer '+$settings.token
            },
        })

        const resp = await response.json();

        console.log("search result is", resp);

        if (resp.message ==="Users retrieved successfully") {
            handleSearchSuccess(resp.data);
        } else {
            console.log("search failed");
        }
    }

    const handleSearchSuccess = (responseData) => {
        if (responseData) {
            $users = responseData;
            showHintUnsuccess = false;
        }
    }


    ////////////////////////////////// load groups //////////////////////////////////////////////////////////

    async function loadGroups() {
        
        const response = await fetch($endpoints.EPpath+$endpoints.EPGroups, {
            method:"GET",
            headers: {
                "Content-Type": "application/json",
                'Accept':'application/json',
                'Authorization': 'Bearer '+$settings.token
            },
        });

        const resp = await response.json();

        console.log ("groups loading response is", resp);

        if (resp.status === false) {
            handleGroupsUnsuccess();

        } else {
            handleGroupsSuccess (resp.data);
        }
    }

    const handleGroupsUnsucces = () => {  
        showHintUnsuccess = true;
    }

    const handleGroupsSuccess = (responseData) => {  
        if (responseData) {
            $groups = responseData;
            createGroupsOptions();
            showHintUnsuccess = false;
        }
    }

    const createGroupsOptions = () => {
        console.log("createGroupsOptions:::, groups are", $groups)
        $options["companies"] = [];
        let obj = {"value": 0, "name":"Bitte auswählen"};
        $options["companies"].push(obj);
        for (let i = 0; i < $groups.length; i++) {
            let obj = {"value": $groups[i].id, "name":$groups[i].entityName};
            $options["companies"].push(obj);
        }
        console.log("companies are now",  $options["companies"]);
    }
    
   
    ////////////////////////////////// load users ///////////////////////////////////////////////////////////

    async function loadUsers (page) {
        console.log($settings)
        const params = '/?page='+page+'&groupsId='+$settings.groupId
        const response = await fetch($endpoints.EPpath+$endpoints.EPUserList+params, {
            method:"GET",
            headers: {
                "Content-Type": "application/json",
                'Accept':'application/json',
                'Authorization': 'Bearer '+$settings.token
            },
            
        });

        const resp = await response.json();

        console.log ("user loading response is", resp);

        if (resp.status === false) {
            handleUnsuccess();

        } else {
            handleSuccess (resp.data, resp.pagination)
        }
    }

    const handleUnsuccess = () => {  
        showHintUnsuccess = true;
    }

    const handleSuccess = (responseData, pagination) => {  
        if (responseData) {
            $settings.pagination = pagination;
            console.log("pagination i handle success", $settings.pagination)
            $users = responseData;
            showHintUnsuccess = false;
        }
    }

    const handlePaginationClick = (pageInt) => {
        loadUsers(pageInt);
    }


    onMount(async () => {
         loadGroups();
	     loadUsers(1);
	});


</script>


<AdminBar callback={handleAdminBarLoadUsers} callbackSearch={handleSearch}></AdminBar>

<div class="daa-main-layout">

    <div></div>

    <div class="daa-adm-1-col">

        <!-- table head -->
        <div class="daa-table-head daa-table-user-cols" role="none">
            
            <div id="pId" class={getHeadCellClass("pId", currentCell)} on:click={handleClickHeadCell} role="none">pId</div>

            <div class="daa-table-double-cell-head">
                <div id="firstName" class={getHeadCellClass("firstName",currentCell)} on:click={handleClickHeadCell} role="none">Vorname</div>
                <div id="lastName" class={getHeadCellClass("lastName", currentCell)} on:click={handleClickHeadCell} role="none">Nachname</div>
            </div>
            <div id="lastChange" class={getHeadCellClass("lastChange",currentCell)} on:click={handleClickHeadCell} role="none">letzte Änd.</div>
            <div id="status" class={getHeadCellClass("status",currentCell)} on:click={handleClickHeadCell} role="none">Status</div>
            <div class="daa-table-cell"></div>
            <div></div>
        </div>

        {#if showHintUnsuccess} 
            <div class="daa-no-data-loaded">No data loaded</div>
        {:else}
        <TableUsers callback={handlePaginationClick}></TableUsers>
        {/if}
      
    </div>

    <div></div>

 </div>

 <div style="height: 20px;"></div>

<Footer positionFix="true"></Footer>
<ModalImpressum></ModalImpressum>
<ModalDeleteUser callback={handleModalDeleteUser}></ModalDeleteUser>

