import { writable } from 'svelte/store';

export const user = writable({

"entityID": "",
"entityName": "Name of company",
"employeeID": "",
"lastName": "Mustermann",
"firstName": "Max",
"namePrefix": "",
"nameAffix": "",
"title": "",
"eMail": "",

"countryName":"Deutschland",
"countryCode":"D",
"zipCode": "",
"location": "",
"street": "",
"streetNumber": "not used",
"additionalAddressLine": "",

"nationality": "",
"nationalityCode": "",
"gender": "",
"genderCode": "",
"birthDate": "12.12.1939",
"birthName": "",
"birthNamePrefix": "",
"birthNameAffix": "",
"birthLocation": "",
"birthCountry": "",
"birthCountryCode": "",

"schoolGraduation": "",
"schoolGraduationCode": "",
"jobGraduation":"", 
"jobGraduationCode":"", 

"IBAN": "",
"BIC": "",
"foreignIBAN": "",
"differentAccountOwner": "",

"taxId": "",
"additionalOccupation": "",
"additionalOccupationCode": "",
"ELSTAMOcclusion": "Das weiß ich nicht",
"ELSTAMOcclusionCode": "-999", 

"taxClass": "",
"taxClassCode": "",
"childrenTaxFreeFactor": "",
"religiousDenomination": "",
"religiousDenominationCode": "",
"specialIncomeTaxTable": "Normalsteuer",
"specialIncomeTaxTableCode": "N",
"officialOrPV": "",
"officialOrPVCode": "",
"totalContributionPrivateHealthInsurance": "",
"startPensionPayment": "",
"ESLSTAMBirthDate": "",

"maritalStatus": "",
"maritalStatusCode": "",
"maritalStatusSince": "",

"healthInsuranceCompany": "",
"healthInsuranceOperatingId": "",
"tree": "P",
"Mini": "Mini",
"SSN":"",
"healthInsuranceType":"",
"childNumber": "",
"childNumberUnder25":0,

}
);

export const currentUserId = writable({
    "id": -99999,
    "employeeId": -99999,
});

export const emptyUser = writable({

    "entityID": "",
    "entityName": "",
    "employeeID": "",
    "lastName": "",
    "firstName": "",
    "namePrefix": "",
    "nameAffix": "",
    "title": "",
    "eMail": "",
    
    "countryName":"Deutschland",
    "countryCode":"D",
    "zipCode": "",
    "location": "",
    "street": "",
    "streetNumber": "not used",
    "additionalAddressLine": "",
    
    "nationality": "ägyptisch",
    "nationalityCode": "",
    "gender": "",
    "birthDate": "01.2.1921",
    "birthName": "myBirthname",
    "birthNamePrefix": "",
    "birthNameAffix": "",
    "birthLocation": "birthlocated somewhere",
    "birthCountry": "Deutschland",
    "birthCountryCode": "",

    "schoolGraduation": "",
    "schoolGraduationCode": "",
    "jobGraduation":"", 
    "jobGraduationCode":"", 
    
    "IBAN": "DE12345678912345678911",
    "BIC": "",
    "foreignIBAN": "",
    "differentAccountOwner": "",
    
    "taxId": "mytaxid",
    "additionalOccupation": "Nein",
    "ELSTAMOcclusion": "Das weiß ich nicht",
    "ELSTAMOcclusionCode": "-999", 
    "taxClass": "6",
    "childrenTaxFreeFactor": "",
    "religiousDenomination": "",
    "specialIncomeTaxTable": "Normalsteuer",
    "specialIncomeTaxTableCode": "N",
    "officialOrPV": "Nein",
    "totalContributionPrivateHealthInsurance": "",
    "startPensionPayment": "22.04.1999",
    "ESLSTAMBirthDate": "3.04.1988",
    
    "maritalStatus": "getrennt lebend",
    "maritalStatusSince": "19.11.2021",
    
    "healthInsuranceCompany": "Der Name der Krankenversicherung",
    "healthInsuranceOperatingId": "",
    "tree": "P",
    "Mini": "Mini",
    "SSN":"",
    "healthInsuranceType":"gesetzlich freiwillig versichert",
    "childNumber":"",
    "childNumberUnder25":0,
    
    }
    );

    
export const dummyUser = writable({

    "entityID": "",
    "entityName": "",
    "employeeID": "",

    "lastName": "Dummy",
    "firstName": "Max",
    "namePrefix": "",
    "nameAffix": "",
    "title": "",
    "eMail": "dummy@vendor.com",
    
    "countryName":"Deutschland",
    "countryCode":"D",
    "zipCode": "12345",
    "location": "Hintertupfingen",
    "street": "Dummystraße",
    "streetNumber": "not used",
    "additionalAddressLine": "",
    
    "nationality": "deutsch",
    "nationalityCode": "D",
    "gender": "männlich",
    "genderCode": "M",
    "birthDate": "23.04.1949",
    "birthName": "mybirthname",
    "birthNamePrefix": "",
    "birthNameAffix": "",
    "birthLocation": "Ganzwoanders",
    "birthCountry": "Deutschland",
    "birthCountryCode": "D",

    "schoolGraduation": "Mittlere Reife",
    "schoolGraduationCode": "3",
    "jobGraduation": "Meister o. Fachschulabschluss", 
    "jobGraduationCode": "3", 

    "IBAN": "DE12345678912345678911",
    "BIC": "",
    "foreignIBAN": "",
    "differentAccountOwner": "",
    
    "taxId": "55060819888",
    "additionalOccupation": "Nein",
    "additionalOccupationCode": "0",
    "ELSTAMOcclusion": "Das weiß ich nicht",
    "ELSTAMOcclusionCode": "-999", 
    "taxClass": "Steuerklasse III",
    "taxClassCode": "3",
    "childrenTaxFreeFactor": "",
    "religiousDenomination": "Römisch-Katholische Kirschensteuer",
    "religiousDenominationCode": "rk",
    "specialIncomeTaxTable": "Normalsteuer",
    "specialIncomeTaxTableCode": "N",

    "officialOrPV": "Ja",
    "officialOrPVCode": "1",
    "totalContributionPrivateHealthInsurance": "",
    "startPensionPayment": "22.04.1999",
    "ESLSTAMBirthDate": "3.04.1988",
    
    "maritalStatus": "geschieden",
    "maritalStatusCode": "G",
    "maritalStatusSince": "19.11.2021",
    
    "healthInsuranceCompany": "AOK PLUS",
    "healthInsuranceOperatingId": "",
    "tree": "P",
    "Mini": "Mini",
    "SSN":"04200843F651",
    "healthInsuranceType":"gesetzlich freiwillig versichert",
    "childNumber": "",
    "childNumberUnder25":0,
    
    }
    );