<script> 

    import { modal } from "../../stores/modal"; 


    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const hideModal = () => {
        $modal.current = "none";
    }

</script>


{#if $modal.current === "impressum"}

<div class="daa-backdrop daa-backdrop-dark" on:click={hideModal} role="none">

    <div class="daa-dialog-container daa-dialog-container-impressum">

        <div class="daa-impressum">

            <h1>Impressum</h1>

            <div class="daa-impressum-bplusr" style="font-size:20px;">b+r - Ihr Partner für digitale Bildung</div>
            <div>b+r Bildung und Reisen GmbH - ein Unternehmen der DAA-Stiftung Bildung und Beruf -</div>
            <br>
            <div>Alter Teichweg 17</div>
            <div>22081 Hamburg</div>
            <div>Telefon: +49 (0) 40 / 35 51 91 0</div>
            <div>Fax: +49 (0) 40 / 35 51 91 1</div>
            <div>E-Mail: info@bplusr.de</div>
            <br>
            <div>Geschäftsführung Thomas Strüver</div>
            <div>Handelsregister Hamburg Nr. 32 274</div>
            <div>Amtsgericht Hamburg</div>
            <div>USt.-IdNr.: DE 118534476</div>
            <br>

            <div>Die b+r Bildung und Reisen GmbH ist Diensteanbieter dieser Internetseite im Sinne des DDG (Digitale-Dienste-Gesetz). Sie handelt im Auftrag von:</div>
           
            <p><strong>DAA Deutsche Angestellten-Akademie GmbH</strong></p>
            <div>Alter Teichweg 19, 22081 Hamburg</div>
            <div>Geschäftsführung: Maria-Carmen Grabenhorst-Hickmann, Dr. Tina Jessica Classen</div>
            <div>Telefax: 040 35094-120</div>
            <div>E-Mail: information@daa.de</div>
           
            <p><strong>DAA-Technikum Gemeinnützige Fernunterrichts-GmbH</strong></p>
            <div>Auf der Union 10, 45141 Essen</div>
            <div>Geschäftsführer: Dr. Martin Bleß</div>
            <div>Telefax: 0201 83 16 311</div>
            <div>E-Mail: info@daa-technikum.de</div>
           
            <p><strong>HFH Hamburger Fern-Hochschule gemeinnützige GmbH</strong></p>
            <div>Alter Teichweg 19, 22081 Hamburg</div>
            <div>Geschäftsführerin: Susanne Möcks-Carone</div>
            <div>Telefax: 040 35094310</div>
            <div>E-Mail: info@hfh-fernstudium.de</div>
       
        </div>

    </div>

</div>

{/if}