<script>

</script>
  

<div class="daa-header">

    <div></div>

    <div class="daa-header-content">

        <div class="daa-logo">
            <img src="svelte/daa_stiftung_logo.svg" alt="DAA Stiftung Logo" />
        </div>

        <div class="daa-head-bar">
            
            <div class="daa-skew">
                <svg x="0px" y="0px" viewBox="0 0 96 200" >
                    <path fill="#FFFFFF" d="M96,0L0,200V0H96z"/>
                    <path fill="#BCDCF5" d="M23.8,150.2L0,0h96v0h-0.5L23.8,150.2z"/>
                </svg>
            </div>
           
            <div class="daa-header-headline">Honorarkräfte</div>
        </div>

    </div>

    <div class="daa-head-right"></div>

</div>
  