<script> 

    import { modal } from "../../stores/modal";     
    import { admin } from "../../stores/admin";
    import { endpoints } from '../../stores/endpoints'; 
    import { settings } from '../../stores/settings';
    import ButtonCancel from "../button/ButtonCancel.svelte";
    import ButtonOk from "../button/ButtonOk.svelte";

    export let data;
    export let callback;

    $:showHintUnsuccess = false;
    $:showHintSuccess = false;


    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const handleCancel = () => {
        showHintSuccess = false;
        showHintUnsuccess = false;
        $modal.current = "none";
    }

    const handleLeaveSuccess = () => {
        showHintSuccess = false;
        showHintUnsuccess = false;
        $modal.current = "none";
        callback("success");
    }

    const handleLeaveUnSuccess = () => {
        showHintSuccess = false;
        showHintUnsuccess = false;
        $modal.current = "none";
        callback("failed");
    }

    const handleOk = () => {
        deleteAdmin();
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    async function deleteAdmin () {

        if(!$admin.id) return;

        const response = await fetch($endpoints.EPpath+$endpoints.EPAdmins +"/"+$admin.id, {
            method:"DELETE",
            headers: {
                "Content-Type": "application/json",
                'Accept':'application/json',
                'Authorization': 'Bearer '+$settings.token
            }
        })

        const resp = await response;

            if (!resp.ok) {
                handleUnsuccess();
            } else {
                handleSuccess();
            }
    }

    const handleUnsuccess = () => {  
        showHintUnsuccess = true;
    }

    const handleSuccess = () => {  
     showHintSuccess = true;
    }


</script>


{#if $modal.current === "deleteAdmin"}

<div class="daa-backdrop daa-backdrop-dark">

    <div class="daa-dialog-container">

        <div class="daa-modal-headline">Löschen</div>

        {#if !showHintUnsuccess && !showHintSuccess} 

        <div class="daa-dialog-hint">Möchten Sie diese(n) Administrator/in löschen?</div>
        <div style="height: 20px;"></div>
        <div class="daa-dialog-hint">{$admin.id} {$admin.firstName} {$admin.lastName}</div>

        <div class="daa-dialog-buttons-container">
            <ButtonCancel active={true} label="Abbrechen" callback={handleCancel}></ButtonCancel>
            <ButtonOk active={true} label="Ok" callback={handleOk}></ButtonOk>
        </div>

        <!-- failed -->
        {#if showHintUnsuccess }

            <div class="daa-login-hint-invalid">Vorgang nicht erfolgreich.</div>
            <div class="daa-dialog-buttons-container">
                <ButtonCancel active={true} label="Abbrechen" callback={handleLeaveUnSuccess}></ButtonCancel>
            </div>
        {/if}

        {/if}

          <!-- successful -->
          {#if showHintSuccess }

          <div class="daa-login-hint-success">Admin erfolgreich gelöscht.</div>
          <div class="daa-dialog-buttons-container">
              <ButtonOk active={true} label="Ok" callback={handleLeaveSuccess}></ButtonOk>
          </div>
       {/if}

    </div>

</div>

{/if}
