
<script>

    // @ts-nocheck
    import { onMount } from 'svelte';  
    import { admins } from '../../stores/admins.js';  
    import { admin } from '../../stores/admin.js'; 
    import { modal } from "../../stores/modal";  
    import Icon from "../icon/Icon.svelte";
    import IconButton from "../icon/IconButton.svelte";


    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const getAdminById = (id) => {

        for (const [key, value] of Object.entries($admins)) {
         if(value.id === id) {
            return value;
         } 
        }
        return false;

    }
    const handleTrashIconClicked = (id) => {

        let findAdmin = getAdminById(id);
      
        if (findAdmin) {
            $admin = {...findAdmin};
        }
        console.log("admin set:::", $admin)
        // $currentAdminId.id = id;
        $modal.current = "deleteAdmin";
    }

    const handleEditIconClicked = (id) => {

        let findAdmin = getAdminById(id);
        if (findAdmin) {
            $admin = {...findAdmin};
        }
        console.log("admin set in edit icon :::", $admin)
        $modal.current = "editAdmin";
    }

    const handleEyeIconClicked = (id) => {

    }

    onMount(async () => {
        
	});

</script>


<div class="daa-table-container">

    <!-- {#each $admins as admin} -->
    {#each Object.entries($admins) as [key, adm]}
   
   <div class="daa-table-row daa-table-admins-cols" role="none">
       
       <div class="daa-table-cell">{adm.id}</div>
   
       <div class="daa-table-double-cell">
           <div class="daa-table-cell">{adm.firstName || ""}</div>
           <div class="daa-table-cell">{adm.lastName  || ""}</div>
       </div>
   
       <div class="daa-table-cell">{adm.company || ""}</div>
   
       <div class="daa-table-cell-icons">
           <IconButton id={adm.id} type="trash" callback={handleTrashIconClicked}></IconButton>
           <IconButton id={adm.id} type="edit" callback={handleEditIconClicked}></IconButton>
       </div>
   
   </div>
   
   {/each}

</div>

<div class="daa-table-foot" >
   { Object.entries($admins).length}
</div> 
