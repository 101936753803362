<script> 

    import { modal } from "../../stores/modal";     
    import ButtonCancel from "../button/ButtonCancel.svelte";
    import ButtonOk from "../button/ButtonOk.svelte";

    export let data;
    export let callback;


    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const handleCancel = () => {
        $modal.current = "none";
    }

    const handleOk = () => {
        callback ("ok");
    }


</script>


{#if $modal.current === "deleteUser"}

<div class="daa-backdrop daa-backdrop-dark">

    <div class="daa-dialog-container">

        <div class="daa-modal-headline">Löschen</div>

        <div class="daa-dialog-hint">Möchten Sie diesen Eintrag löschen?</div>
        <div style="height: 20px;"></div>
        <div class="daa-dialog-hint">user data should go here</div>

        <div class="daa-dialog-buttons-container">
            <ButtonCancel active={true} label="Abbrechen" callback={handleCancel}></ButtonCancel>
            <ButtonOk active={true} label="Ok" callback={handleOk}></ButtonOk>
        </div>

    </div>

</div>

{/if}
