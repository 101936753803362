    
export let newAdminObj = {
    adminData: {
        "id": "",
        "username": "",
        "password": "",
        "firstName": "",
        "lastName": "",
        "eMail": "",
        "groupsId": "",
        "groupsName": ""
    }
}

export let newEmptyAdminObj = {
    adminData: {
        "id": "",
        "username": "",
        "password": "",
        "firstName": "",
        "lastName": "",
        "eMail": "",
        "groupsId": "",
        "groupsName": ""
    }

}