<script>

    import { fields } from '../../stores/form.js';

    export let fieldName = "none";
    export let labelClass = "daa-label-w400";
   

    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    
</script>


<div class="daa-input-grid">

    <div></div>
    
    <div class="daa-hint-box">
        <div class="daa-hint-box-headline">Hinweis</div>
        <div>Falls Sie etwas nicht wissen, bitte auf <i>Nein</i> klicken.</div>
    </div>
       
</div>



