<script>

    import { fields } from '../../stores/form.js';
    import Icon from '../icon/Icon.svelte';
 
    export let fieldName = "none";
    export let labelClass = "daa-label-w300";
   
    let text = $fields[fieldName].text;
   
    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    
    export const validate = (bool) => {
        return "valid";
    };

    export const getValue = () => {
        return "";
    };

    export const update = () => {
    };


</script>


<div class="daa-input-grid {labelClass}">

    <div class="daa-label-container"></div>
    
    <div class="daa-tree-explain">
        
        <div >{text}</div>
        
       
    </div>
       
</div>
