<script>

    import { onMount, onDestroy } from 'svelte'; 
    import { fields } from '../../stores/form.js';
    import { settings } from '../../stores/settings.js';
    import { validateStreet, validateChars } from '../helper/validate.js';
    import HelpButton from '../helpTextBox/HelpButton.svelte';

    export let fieldName = "none";
    export let labelClass = "daa-label-w400";
    export let store;
   
    let data = $fields[fieldName];
    let inputFieldStreet;
    //$:inputFieldNumber = store["valueStreetNumber"];
    
    $:currentValue = store["street"];
    $:showWarning = false;
    $:checkSilently = false;

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const handleInput = (e) => {
        currentValue = e.target.value;
        saveToStore();
        showWarning = false;
        $settings.showWarningInFooter = false;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    export function validate(boolSilent) {

        checkSilently = !boolSilent;

         // let rules = data.validate;
        // if (!rules) return "valid";
        // let checkChars = validateStreet (inputFieldStreet.value,inputFieldNumber.value, rules);
        // if(checkChars !=="valid") showWarning = true;
        // return checkChars;

        let rules = data.validate;
        if (!rules) return "valid";
        let checkChars = validateChars (inputFieldStreet.value, rules);
        if(checkChars !=="valid") showWarning = true;
        return checkChars;
    }

    export const getValue = () => {
    };

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    export const update = () => {
        currentValue = store[fieldName];
        inputFieldStreet.value = currentValue;
    };

    const saveToStore = () => {
        store[fieldName] = currentValue;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    onMount(async () => {
        update();

    });
    
    onDestroy(() => {
        saveToStore();
    });

    /////////////////////////////////////////////////////////////////////////////////////////////////////////


</script>


<div class="daa-input-grid {labelClass}">

    <div class="daa-label-container">
        <!-- <label class="daa-input-label daa-fnt-std" for={fieldName}>{data.name}</label> -->
        <label class="daa-input-label daa-fnt-std" for={fieldName+"user"}>Straße und Hausnummer</label>
        {#if data.required} 
            <div class="daa-hint-asterix">*</div> 
        {/if}
    </div>

    <div class="daa-input-text-hint-container">

        <div class="daa-input-street daa-input-street-no-number">

            <input 
                class="daa-input-text daa-fnt-std" 
                style={(showWarning === false ) ? "": "border: 1px solid red;"}
                type="text" 
                id={fieldName+"user"} 
                name={fieldName+"user"} 
                bind:this={inputFieldStreet}
                on:input={handleInput}> 

            <!-- <input 
                class="daa-input-text daa-fnt-std" 
                style={(showWarning === false ) ? "": "border: 1px solid red;"}
                type="text" 
                id="streetNumber"  
                name="streetNumber" 
                bind:this={inputFieldNumber}
                bind:value={valueStreetNumber}
                on:input={handleInput}>  -->
        </div>

        <HelpButton show={data.useHelp} ref={fieldName}></HelpButton>
       
    </div>
       
</div>


<!-- Error -->
{#if showWarning}

<div class="daa-input-grid {labelClass}">
    <div></div> 
    <div class="daa-warning">{data.error}</div>
</div>

{/if}

